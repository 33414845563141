export const getPointLatLngFromEvent = (event, map) => {
    // Determine if it's a touch event or mouse event
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;

    const rect = map.getDiv().getBoundingClientRect();
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    const projection = map.getProjection();

    if (projection) {
        const bounds = map.getBounds();
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const topRight = projection.fromLatLngToPoint(ne);
        const bottomLeft = projection.fromLatLngToPoint(sw);
        const scale = Math.pow(2, map.getZoom());

        const worldPoint = new google.maps.Point(
            x / scale + bottomLeft.x,
            y / scale + topRight.y,
        );
        return projection.fromPointToLatLng(worldPoint);
    }
    return null;
};

export function getPathLengthInFt(path) {
    const distanceInMeters = google.maps.geometry.spherical.computeLength(path);
    return distanceInMeters * 3.28084;
}

export function getPathAreaInFt(points) {
    const areaInMeters = google.maps.geometry.spherical.computeArea(points);
    return areaInMeters * 10.7639;
}
