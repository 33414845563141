
export const imageTooltipTitleStyle = {
    maxWidth: "800px", // Increase tooltip container size
    maxHeight: "800px",
    background: "#fff",
    padding: "10px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export const imageTooltipTitleImageStyle = {
    width: "100%", // Make it fill the container
    height: "auto", // Keep aspect ratio
    maxWidth: "800px", // Allow the image to be larger
    maxHeight: "800px",
    objectFit: "contain",
};

export const imageTooltipPopperStyle = {
    modifiers: [
        {
            name: "preventOverflow",
            options: {
                boundary: "window",
            },
        },
    ],
    sx: {
        "& .MuiTooltip-tooltip": {
            maxWidth: "none", // Remove default tooltip width restriction
        },
    },
};
