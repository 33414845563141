import React from "react";
import PropTypes from "prop-types";
import {AuditTable} from "Pages/Audits/AuditTable";

export const AuditMapSelectedAreas = ({
    tidySelectedAreas,
    isViewOnly,
    deleteAreaCall = null,
    editAreaCall = null,
    isPublicAudit = false,
}) => {
    return (
        <AuditTable
            headerName={"Areas"}
            dimensionHeader={"Dim (ft²)"}
            isViewOnly={isViewOnly}
            items={tidySelectedAreas}
            elementType={"area"}
            onDelete={deleteAreaCall}
            onEdit={editAreaCall}
            isPublicAudit={isPublicAudit}
        />
    );
};

AuditMapSelectedAreas.propTypes = {
    tidySelectedAreas: PropTypes.array.isRequired,
    isViewOnly: PropTypes.bool.isRequired,
    deleteAreaCall: PropTypes.func,
    editAreaCall: PropTypes.func,
    isPublicAudit: PropTypes.bool,
};
