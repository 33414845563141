import React from "react";
import PropTypes from "prop-types";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {useTheme} from "styled-components";

const SVPNavigationSpeedDial = ({actions}) => {
    const theme = useTheme();

    const scrollToElementWithOffset = (elementId, offset = 100) => {
        const element = document.getElementById(elementId);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - offset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    };

    return (
        <SpeedDial
            ariaLabel="Navigation Speed Dial"
            sx={{
                "position": "fixed",
                "bottom": 16,
                "right": 16,
                "& .MuiFab-primary": {
                    "backgroundColor": theme.color.secondary,
                    "&:hover": {
                        backgroundColor: theme.color.ctaHoverBg,
                    },
                },
            }}
            icon={<SpeedDialIcon />}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => scrollToElementWithOffset(action.href, 80)}
                />
            ))}
        </SpeedDial>
    );
};

SVPNavigationSpeedDial.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            icon: PropTypes.node.isRequired,
            href: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default SVPNavigationSpeedDial;
