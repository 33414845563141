import styled from "styled-components";

export const AuditContainer = styled.div`
    .intro .subTitle{
        opacity: 0.5;
    }
    
    button[type="submit"], button[type="button"]{
        text-transform: capitalize;
    }

    .MuiButton-contained{
        background-color: ${({theme}) => theme.color.info} !important;
    }
    .MuiButton-outlined{
        border-color: ${({theme}) => theme.color.info} !important;
    }

    textarea{
        resize: none;
        height: 150px;
        border: 1px solid ${({theme}) => theme.color.border};
    }
        
    .auditTable .header{
        background: ${({theme}) => theme.color.border};
    }
    .auditTable .MuiDataGrid-cell {
        display: flex;
        justify-content: start;
        align-items: center;
    }
`;