import React, {useEffect, useState} from "react";
import {fetchSelectionOptions} from "Services/ServicesUtils";
import {API_SUBSCRIBER} from "Models/Subscriber/SubscriberEndpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {SubscriberFormFields} from "Pages/Subscriber/SubscriberFormFields";
import {GSPSPageWrapper, GSPSPopUpHeader} from "Components/Common/GSPSStyledContainers.styles";
import PropTypes from "prop-types";
import {useAddSubscriberMutation} from "api/SubasciberTableAPI";
import {UserIndustryTypeID, UserPermissionGroupIDs, UserRoleID} from "Models/User/UserConstants";

export const SubscriberCreator = ({show, setShow}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [defaultUnits, setDefaultUnits] = useState();
    const [industries, setIndustries] = useState();
    const [addSubscriber] = useAddSubscriberMutation();

    useEffect(() => {
        fetchSelectionOptions(API_SUBSCRIBER.defaultUnits, setDefaultUnits, "name");
        fetchSelectionOptions(API_SUBSCRIBER.industries, setIndustries, "name");
    }, []);

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            await submitForm(values);
        } catch (e) {
            console.error("Something is wrong with saving Subscriber details");
            insertNotification("Error", `can't add Subscriber: ${e.message}`, "error");
        } finally {
            setIsSubmitting(false);
            setShow(false);
        }
    };

    const submitForm = async (values) => {
        const data = {
            name: values["subscriber_name"],
            address: {
                apt_num: values.address.apt_num,
                street_num: values.address.street_num,
                street_name: values.address.street_name,
                city: values.address.city,
                state: values.address.state,
                zip: values.address.zip,
            },
            website: values["website"],
            phone: values["phone"],
            default_unit: values["default_unit"],
            industry: values["industry"],
            default_user: {
                first_name: values["first_name"],
                last_name: values["last_name"],
                email: values["email"],
                group_permission_id: UserPermissionGroupIDs.GROUP_ADMIN,
                industry_type_id: UserIndustryTypeID.CONTRACTOR,
                role_id: UserRoleID.OTHER,
            },
        };

        try {
            const createdSubscriber = await addSubscriber(data).unwrap();
            insertNotification("Success", `Subscriber ${createdSubscriber .name} has been created ..`, "success");
            setShow(false);
        } catch (error) {
            const failureReason = JSON.stringify(error.data);
            insertNotification("Error", `Can't create subscriber: ${failureReason}`, "error");
        }
    };

    return (
        <GSPSPageWrapper
            className="show"
            role="dialog"
            show={show}
            centered
            size={"lg"}
        >
            <GSPSPopUpHeader className="subtitle-lg">
                Create Subscriber
            </GSPSPopUpHeader>
            <SubscriberFormFields
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                setShow={setShow}
                defaultUnits={defaultUnits}
                industries={industries}
            />
        </GSPSPageWrapper>
    );
};

SubscriberCreator.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
};
