import React, {useEffect, useState} from "react";
import {makeDeleteRequest, makeFormDataUpdateRequest, makeGetRequest} from "Services/ServicesUtils";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {insertNotification} from "Utils/NotificationsUtils";
import {AccountForm} from "Components/Accounts/AccountForm";
import {GSPSPageWrapper, GSPSPopUpFooter, GSPSPopUpHeader} from "Components/Common/GSPSStyledContainers.styles";
import {GSPSCancelButton} from "Components/Common/GSPSCancelButton";
import {useUpdateAccountMutation} from "api/AccountTableAPI";
import PropTypes from "prop-types";

export const Account = ({show, setShow, accountID}) => {
    const {handleSubmit, register, setValue, reset, formState: {errors}} = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasLogo, setHasLogo] = useState(false);
    const [account, setAccount] = useState();
    const [auditTypes, setAuditTypes] = useState([]);
    const [updateAccount] = useUpdateAccountMutation();

    useEffect(() => {
        makeGetRequest(`${API_ACCOUNTS.accounts}${accountID}/`).then(
            (res) => {
                setAccount(res);
                reset(res);
            },
        );
    }, [reset, accountID]);
    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            await submitImage(values);
            await submitForm(values);
            setShow(false);
        } catch (e) {
            console.error("Something is wrong with saving account details");
        } finally {
            setIsSubmitting(false);
        }
    };

    const submitForm = async (values) => {
        const selectedAuditTypes =
            auditTypes.filter((item) => item.selected).map((item) => item.key);
        if (selectedAuditTypes.length === 0) {
            insertNotification("Error", "Audit Types is Required", "error");
            return;
        }
        const {logo, ...otherValues} = values;
        const updateData = {
            ...otherValues,
            account_audit_types_ids: selectedAuditTypes,
            account_type_id: values.account_type_id,
            account_admins_ids: values.account_admins_ids === "" ? [] : [values.account_admins_ids],
            credits: values.credits === "" ? 0 : values.credits,
        };
        try {
            const updatedAccount = await updateAccount({
                id: accountID, updatedItem: {...updateData},
            }).unwrap();
            insertNotification("Success", `Account ${updatedAccount.name} has been updated ..`, "success");
        } catch (error) {
            const failureReason = JSON.stringify(error);
            insertNotification("Error", `Can't update account: ${failureReason}`, "error");
        }
    };

    const submitImage = async (values) => {
        if (hasLogo) {
            const formData = new FormData();
            formData.append("logo", values.logo[0]);
            return makeFormDataUpdateRequest(API_ACCOUNTS.accountLogo(accountID), formData)
                .catch((e) => {
                    console.log(e);
                });
        } else {
            return makeDeleteRequest(API_ACCOUNTS.accountLogo(accountID))
                .catch((e) => {
                    console.log(e);
                });
        }
    };

    return (
        <>
            <GSPSPageWrapper className={show} role="dialog"
                show={true}
                centered={true}
                size={"lg"}
                container={document.querySelector("#root .sc-bbSYpP")}
            >
                <GSPSPopUpHeader className="subtitle-lg">
                    Account Information
                </GSPSPopUpHeader>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <AccountForm
                        account={account}
                        errors={errors}
                        register={register}
                        setImageValue={setValue}
                        auditTypes={auditTypes}
                        setAuditTypes={setAuditTypes}
                        setValue={setValue}
                        setHasLogo={setHasLogo}
                    />
                    <GSPSPopUpFooter>
                        <GSPSCancelButton
                            isDisabled={false}
                            controlSize={2}
                            onClick={(event) => {
                                event.preventDefault();
                                setShow(false);
                            }}
                            buttonText={"Cancel"} />
                        <GSPSSubmitButton
                            isDisabled={false}
                            isLoading={isSubmitting}
                            controlSize={2}
                            buttonText={"Save"} />
                    </GSPSPopUpFooter>
                </Form>
            </GSPSPageWrapper>
        </>
    );
};

Account.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    accountID: PropTypes.number,
};
