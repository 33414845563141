export const containerStyle = {
    width: "100%",
    height: "70vh",
    position: "relative",
};

export const hoverItemStyle = {
    background: "#5a1335",
    color: "white",
    borderRadius: "4px",
    padding: "6px 10px",
    whiteSpace: "nowrap",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    minWidth: "100px",
    height: "50px",
    opacity: "90%",
    fontSize: 14,
    fontWeight: "light",
    transform: "translate(30%, -100%)",
};

export const getAreaAnnotationStyle = (borderColor) => {
    return {
        position: "absolute",
        background: "#F0F0F0",
        border: "4px solid" + borderColor,
        borderRadius: "50%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        fontSize: "21px",
        fontWeight: "bold",
    };
};

export const getLineAnnotationStyle = (borderColor) =>{
    return {
        position: "absolute",
        background: "#F0F0F0",
        border: "4px solid " + borderColor,
        borderRadius: "50%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        fontSize: "21px",
        fontWeight: "bold",
    };
};

export const getItemStyle = (backgroundColor) => {
    return {
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: backgroundColor,
        marginRight: "6px",
    };
};

export const currentLocationButtonStyle = {
    position: "absolute",
    bottom: "190px",
    right: "5px",
    zIndex: 5,
    border: "none",
    backgroundColor: "white",
    padding: "4px",
    width: "20",
    height: "20",
    cursor: "pointer",
    fontSize: "15px",
    color: "black",
};

export const currentAreaAndDistanceStyle = {
    position: "absolute",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 10,
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "5px",
};

export const baseButtonStyle = {
    position: "absolute",
    left: "8px",
    padding: "0px 0px",
    width: "70px",
    backgroundColor: "white",
    color: "black",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "12px",
};

export const undoButtonStyle = {
    ...baseButtonStyle,
    top: "160px",
};

export const saveButtonStyle = {
    ...baseButtonStyle,
    top: "90px",
};

export const cancelButtonStyle = {
    ...baseButtonStyle,
    top: "60px",
};

export const locationButtonStyle = {
    padding: "0px 0px",
    width: "70px",
    backgroundColor: "white",
    color: "black",
    border: "none",
    cursor: "pointer",
    borderRadius: "5px",
    fontSize: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const locationLabelStyle = {
    fontSize: "10px",
    display: "flex",
    alignItems: "right",
};

export const locationInputStyle = {
    marginRight: "5px",
    transform: "scale(1.2)",
    verticalAlign: "middle",
};

export const locationDivStyle = {
    position: "absolute",
    top: "120px",
    left: "8px",
    zIndex: 1000,
};

