import React from "react";
import {Col} from "react-bootstrap";
import PropTypes from "prop-types";
import {SVPDeleteButtonStyle} from "./SVPDeleteButton.styles";

export const SVPDeleteButton = ({
    onClick,
    buttonText = "Delete",
    offset = 0,
    isDisabled = false,
}) => {
    return <>
        <Col sm={{offset: offset}}>
            <SVPDeleteButtonStyle disabled={isDisabled} type="button" onClick={onClick}>
                {buttonText}
            </SVPDeleteButtonStyle>
        </Col>
    </>;
};

SVPDeleteButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
    controlSize: PropTypes.number,
    offset: PropTypes.number,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
};
