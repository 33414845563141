// eslint-disable-next-line no-undef
export function getPrefix() {
    // eslint-disable-next-line no-undef
    return process.env.REACT_APP_API_HOST + "api/";
}

export function getCorePrefix() {
    return `${getPrefix()}core/`;
}

const prefix = getPrefix();
const corePrefix = getCorePrefix();
const userRegistrationPrefix = "user_registration/";

export const API_USERS = {
    users: `${corePrefix}users/`,
    usersDropdown: `${corePrefix}users-dropdown/`,
    login: `${prefix}token/`,
    refreshToken: `${prefix}token/refresh/`,
    logout: `${prefix}auth/logout/`,
    forgotPassword: `${prefix}${userRegistrationPrefix}send-reset-password-link/`,
    resetPassword: `${prefix}${userRegistrationPrefix}reset-password/`,
    roles: `${corePrefix}users/roles/`,
    groupPermissions: `${corePrefix}users/group_permissions/`,
    industryTypes: `${corePrefix}users/industry_types/`,
};

export const API_HISTORY = {
    users: `${corePrefix}users-history/`,
    accounts: `${corePrefix}accounts-history/`,
    locations: `${corePrefix}locations-history/`,
    audits: `${corePrefix}audits-history/`,
};

export const WAFFLE_SCRIPT = `${prefix}waffle/wafflejs`;
