import React from "react";
import {Button, Col, Form, InputGroup} from "react-bootstrap";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import {GSPSContainer, RequiredFormFieldIcon} from "./GSPSLabeledInput.styles";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {SVPToolTipIcon} from "Components/ToolTip/SVPToolTipIcon";


export const PatternType = {
    ZIP: {
        value: /^\d{5}$/,
        message: "Zip code should be 5 digits",
    },
    EMAIL: {
        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        message: "Please use a valid email format",
    },
    PASSWORD: {
        value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&.,;:_^(){}[\]<>#+=|~-]{8,}$/,
        message: "Password must be at least 8 characters long and include both letters and numbers",
    },
};

export const GSPSLabelledInput = ({
    id,
    labelName,
    controlType,
    register,
    errors,
    placeHolder,
    value,
    isInvalid,
    patternType,
    validate,
    isRequired = false,
    isDisabled = false,
    isPhone = false,
    isPassword = false,
    readOnly = false,
    hiddenLabel = false,
    isShowPassword = false,
    setIsShowPassword,
    onKeyDown,
    tooltipMessage = "",
}) => {
    let displayPlaceholder = placeHolder;
    if (hiddenLabel && !isRequired && placeHolder) {
        displayPlaceholder = `${placeHolder} (Optional)`;
    }

    function getErrorMessage() {
        if (!errors) {
            return;
        }

        const [baseID, secondaryID] = id.split(".");
        const baseErrorDict = errors?.[baseID];

        if (baseErrorDict && secondaryID) {
            return baseErrorDict[secondaryID]?.message;
        }

        return baseErrorDict?.message;
    }

    const validatePhoneNumber = (value) => {
        const unmaskedValue = value.replace(/\D/g, "");
        return (unmaskedValue.length === 10 || unmaskedValue.length === 0) || "Phone number must be exactly 10 digits.";
    };

    return <>
        <GSPSContainer className="my-3">
            {
                !hiddenLabel &&
                    <label htmlFor={id} className="pb-0 label-sm">
                        {labelName}: {isRequired && <RequiredFormFieldIcon>* </RequiredFormFieldIcon>}
                        {tooltipMessage &&
                            <SVPToolTipIcon
                                fontSize="15px"
                                placement="top"
                                message={tooltipMessage}
                            />
                        }
                    </label>
            }

            <Col>
                {isPhone ?
                    <InputMask
                        mask="(999) 999-9999"
                        defaultValue={value || "-"}
                        disabled={isDisabled}
                        readOnly={readOnly}
                        required={isRequired}
                        {...register(
                            id,
                            {
                                required: isRequired && {value: true, message: "This field is required."},
                                pattern: patternType && patternType,
                                validate: (value) => validate || validatePhoneNumber(value),
                            },
                        )}
                    >
                        {(inputProps) => (
                            <Form.Control
                                {...inputProps}
                                id={id}
                                type={controlType}
                                placeholder={placeHolder}
                                isInvalid={isInvalid || getErrorMessage()}
                            />
                        )}
                    </InputMask> :
                    isPassword ?
                        <InputGroup>
                            <Form.Control
                                id={id}
                                type={controlType}
                                placeholder={displayPlaceholder}
                                defaultValue={value}
                                disabled={isDisabled}
                                isInvalid={isInvalid || getErrorMessage()}
                                readOnly={readOnly}
                                onKeyDown={(e) => {
                                    if (onKeyDown) onKeyDown(e);
                                }}
                                {...register(
                                    id,
                                    {
                                        required: isRequired && {value: true, message: "This field is required."},
                                        pattern: patternType && patternType,
                                        validate: validate,
                                    },
                                )}
                            />
                            <InputGroup.Text>
                                <Button
                                    variant="outline"
                                    onClick={() => setIsShowPassword(!isShowPassword)}
                                    aria-label="Toggle password visibility"
                                    style={{
                                        padding: "4px", // Smaller padding
                                        fontSize: "14px", // Reduce font size
                                        width: "30px", // Set button width
                                        height: "30px", // Set button height
                                        display: "flex", // Align icon properly
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {isShowPassword ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
                                </Button>
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                                {isInvalid || getErrorMessage()}
                            </Form.Control.Feedback>
                        </InputGroup> :
                        <Form.Control
                            id={id}
                            type={controlType}
                            placeholder={displayPlaceholder}
                            defaultValue={value}
                            disabled={isDisabled}
                            isInvalid={isInvalid || getErrorMessage()}
                            readOnly={readOnly}
                            onKeyDown={(e) => {
                                if (onKeyDown) onKeyDown(e);
                            }}
                            {...register(
                                id,
                                {
                                    required: isRequired && {value: true, message: "This field is required."},
                                    pattern: patternType && patternType,
                                    validate: validate,
                                },
                            )}
                        />

                }
                <Form.Control.Feedback type="invalid">
                    {isInvalid || getErrorMessage()}
                </Form.Control.Feedback>
            </Col>
        </GSPSContainer>
    </>;
};


GSPSLabelledInput.propTypes = {
    id: PropTypes.string.isRequired,
    labelName: PropTypes.string,
    controlType: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object,
    patternType: PropTypes.object,
    placeHolder: PropTypes.string,
    value: PropTypes.string,
    isInvalid: PropTypes.object,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    validate: PropTypes.func,
    onKeyDown: PropTypes.func,
    isPhone: PropTypes.bool,
    isPassword: PropTypes.bool,
    readOnly: PropTypes.bool,
    hiddenLabel: PropTypes.bool,
    isShowPassword: PropTypes.bool,
    setIsShowPassword: PropTypes.func,
    tooltipMessage: PropTypes.string,
};
