import {pdf} from "@react-pdf/renderer";

export const downloadPdf = async (doc, title) => {
    const blob = await pdf(doc).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const previewPdf = async (doc) => {
    const blob = await pdf(doc).toBlob(); // Convert the document to a blob
    const url = URL.createObjectURL(blob); // Create a URL for the blob
    window.open(url); // Open the PDF in a new tab for preview
};