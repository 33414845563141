import React, {createContext, useEffect, useState} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {Container} from "react-bootstrap";
import {getPathElementMap, getUnauthenticatedPathElementMap, unRedirectionPaths} from "AppRoutes";
import {TopMenu} from "Pages/TopMenu/TopMenu";
import {ReactNotifications} from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {AppStyle} from "Styles/Pages.styles";
import {useCurrentUser} from "Models/User/useCurrentUser";
import theme, {AvailableThemes} from "theme";
import {ThemeProvider} from "styled-components";
import {LoadScript} from "@react-google-maps/api";
import GSPSFooter from "Components/Common/GSPSFooter";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {makePostRequest} from "Services/ServicesUtils";
import {API_USERS, WAFFLE_SCRIPT} from "Services/Endpoints";

export const UserContext = createContext();

const App = () => {
    useEffect(() => {
        loadWaffleScript();
    }, []);

    const loadWaffleScript = () => {
        const script = document.createElement("script");

        script.src = WAFFLE_SCRIPT;
        script.async = true;

        document.body.appendChild(script);
    };

    const isValidRefreshToken = () => {
        const token = localStorage.getItem("refresh_token");

        if (!token || token === "undefined") {
            return false;
        }
        const currentTime = new Date().getTime();
        const decoded = jwtDecode(token);
        return !((currentTime / 1000) > decoded["exp"]);
    };

    const [hasValidRefreshToken, setHasValidRefreshToken] = useState(Boolean(isValidRefreshToken()));
    const contextUser = useCurrentUser();
    const [stateUser, setCurrentUser] = useState({});
    const [currentTheme] = useState(AvailableThemes.light);

    function SetCurrentUser(user) {
        useEffect(() => {
            setCurrentUser(user);
        }, [user]);
    }

    const currentloggedUser = Object.keys(stateUser).length == 0 ? contextUser : stateUser;

    const authenticatedPathElementMap = getPathElementMap(SetCurrentUser, resetCurrentUserSession);
    const unauthenticatedPathElementMap = getUnauthenticatedPathElementMap(setHasValidRefreshToken);

    function resetCurrentUserSession(navigate) {
        makePostRequest(API_USERS.logout)
            .then(() => {
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                setHasValidRefreshToken(false);
                navigate("/");
            });
    }

    return (
        <>
            <ReactNotifications />
            <ThemeProvider theme={theme[currentTheme]}>
                <AppStyle>
                    <Container fluid={true} className="p-0">
                        <BrowserRouter>
                            {hasValidRefreshToken &&
                                <Routes>
                                    {authenticatedPathElementMap.map((data) => {
                                        return (
                                            <Route
                                                key={data.path}
                                                path={data.path}
                                                element={
                                                    <>
                                                        <UserContext.Provider value={{user: currentloggedUser}}>
                                                            <LoadScript
                                                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                                                libraries={["places", "drawing", "geometry"]}
                                                            >
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                                    <TopMenu
                                                                        resetCurrentUserSession={resetCurrentUserSession}
                                                                    />
                                                                    {data.element}
                                                                    <GSPSFooter />

                                                                </LocalizationProvider>
                                                            </LoadScript>
                                                        </UserContext.Provider>
                                                    </>
                                                }
                                            />
                                        );
                                    })};
                                </Routes>
                            }
                            <Routes>
                                {unauthenticatedPathElementMap.map((data) => {
                                    if (hasValidRefreshToken && location.pathname.startsWith("/public-audits/")) return null;
                                    return (
                                        <Route
                                            key={data.path}
                                            path={data.path}
                                            element={
                                                hasValidRefreshToken ?
                                                    <>
                                                        {!["*", "/"].includes(data.path) &&
                                                            !unRedirectionPaths.includes(data.path) &&
                                                            <Navigate to={{pathname: "/dashboard"}} />
                                                        }
                                                    </> :
                                                    <>
                                                        <LoadScript
                                                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                                            libraries={["places", "drawing", "geometry"]}
                                                        >
                                                            {data.element}
                                                        </LoadScript>
                                                    </>
                                            }
                                        />
                                    );
                                })};
                            </Routes>
                        </BrowserRouter>
                        {/* <div className="form-check form-switch">
                    <input className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onClick={() => switchTheme()}>
                    </input>
                </div> */}
                    </Container>
                </AppStyle>
            </ThemeProvider>
        </>
    );
};

export default App;
