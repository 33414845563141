import {enqueueSnackbar} from "notistack";

export function insertNotification(title, message, type, duration = 2000) {
    enqueueSnackbar({
        variant: type,
        autoHideDuration: duration,
        anchorOrigin: {vertical: "bottom", horizontal: "left"},
        message: message,
        title: title,
    });
}
