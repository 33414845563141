import React, {useEffect, useState} from "react";
import {
    fetchSelectionOptions,
    makeDeleteRequest,
    makeFormDataUpdateRequest,
    makeGetRequest,
    makeUpdateRequest,
} from "Services/ServicesUtils";
import {API_SUBSCRIBER} from "Models/Subscriber/SubscriberEndpoints";
import {useParams} from "react-router-dom";
import {insertNotification} from "Utils/NotificationsUtils";
import {UserPermissions} from "Models/User/UserUtils";
import {SubscriberBodyContainer} from "./Subscriber.styles";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {SubscriberFormFields} from "Pages/Subscriber/SubscriberFormFields";

export const SubscriberEditor = () => {
    const {subscriberID} = useParams();
    const [subscriber, setSubscriber] = useState();
    const [defaultUnits, setDefaultUnits] = useState();
    const [industries, setIndustries] = useState();
    const [isDeleteLogo, setIsDeleteLogo] = useState(false);
    const isSuperAdmin = UserPermissions.useIsSuperAdmin();
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!isSuperAdmin) {
            return;
        }
        makeGetRequest(`${API_SUBSCRIBER.subscribers}${subscriberID}/`).then(
            (res) => {
                setSubscriber(res);
                setIsDeleteLogo(false);
            },
        );
        fetchSelectionOptions(API_SUBSCRIBER.defaultUnits, setDefaultUnits, "name");
        fetchSelectionOptions(API_SUBSCRIBER.industries, setIndustries, "name");
    }, [subscriberID]);

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            if (!isDeleteLogo && values.logo) {
                await submitImage(values);
            } else if (isDeleteLogo) {
                try {
                    await deleteImage();
                } catch (err) {
                    console.log(err); // do nothing with err
                }
            }
            await submitForm(values);
        } catch (e) {
            console.error("Something is wrong with saving Subscriber details");
        } finally {
            setIsSubmitting(false);
        }
    };

    const submitForm = async (values) => {
        const body = {
            id: {subscriber},
            name: values["subscriber_name"],
            address: {
                apt_num: values.address.apt_num,
                street_num: values.address.street_num,
                street_name: values.address.street_name,
                city: values.address.city,
                state: values.address.state,
                zip: values.address.zip,
            },
            website: values["website"],
            phone: values["phone"],
            default_unit: values["default_unit"],
            industry: values["industry"],
            is_active: values["is_active"],
            max_users_count: values["max_users_count"] || null,
        };

        makeUpdateRequest(`${API_SUBSCRIBER.subscribers}${subscriberID}/`, body)
            .then((jsonRes) => {
                insertNotification("Success",
                    `Subscriber ${jsonRes.name} has been updated ..`,
                    "success");
                return jsonRes;
            }).catch((error) => {
                error.then((errorMessage) => {
                    const failureReason = JSON.stringify(errorMessage);
                    insertNotification("Error", `can't update subscriber ${failureReason}`, "error");
                });
            });
    };

    const submitImage = async (values) => {
        const formData = new FormData();
        formData.append("logo", values.logo[0]);
        return makeFormDataUpdateRequest(API_SUBSCRIBER.subscriberLogo(subscriberID), formData);
    };

    const deleteImage = async () => {
        return makeDeleteRequest(API_SUBSCRIBER.subscriberLogo(subscriberID));
    };

    if (!isSuperAdmin) {
        return null;
    }

    return (
        <>
            <GSPSBreadCrumbs staticCrumb="Subscriber Information" />
            {subscriber &&
                <>
                    <SubscriberBodyContainer>
                        <SubscriberFormFields
                            subscriber={subscriber}
                            onSubmit={onSubmit}
                            defaultUnits={defaultUnits}
                            industries={industries}
                            isSubmitting={isSubmitting}
                            setIsDeleteLogo={setIsDeleteLogo}
                        />
                    </SubscriberBodyContainer>
                </>
            }
        </>
    );
}
    ;
