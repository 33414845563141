import {DrawingManager} from "@react-google-maps/api";
import React from "react";
import PropTypes from "prop-types";

export const DrawingMode = {
    POLYLINE: "polyline",
    POLYGON: "polygon",
};

export const SVPDrawingManager = ({
    drawingMode,
    onPolylineComplete,
    onPolygonComplete,
}) => {
    return (
        <DrawingManager
            drawingMode={drawingMode}
            options={{
                drawingControl: false,
                polylineOptions: {
                    strokeColor: "red",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    editable: true,
                },
                polygonOptions: {
                    strokeColor: "blue",
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    editable: true,
                },
            }}
            onPolygonComplete={(polygon) => {
                polygon.type = DrawingMode.POLYGON;
                onPolygonComplete(polygon);
            }}
            onPolylineComplete={onPolylineComplete}
        />
    );
};

SVPDrawingManager.propTypes = {
    drawingMode: PropTypes.oneOf([DrawingMode.POLYLINE, DrawingMode.POLYGON]),
    onPolylineComplete: PropTypes.func.isRequired,
    onPolygonComplete: PropTypes.func.isRequired,
};
