import React from "react";
import PropTypes from "prop-types";
import {QuestionsContainer} from "./AuditQuestions.styles";
import {Divider} from "@mui/material";
import _ from "lodash";

export const AuditQuestions = ({
    questions,
    auditorAnswers,
    isReadOnly,
    handleBlur,
    handleChange,
}) => {
    const charLimit = 200;

    function handleInputChange(index, textValue) {
        if (!isReadOnly) {
            handleChange(index, textValue);
        }
    }

    function handleInputBlur() {
        if (!isReadOnly) {
            handleBlur();
        }
    }

    return (
        <QuestionsContainer>
            {questions.length > 0 && (
                <>
                    <div>
                        <h1 className="subtitle-md mb-3">Site questions</h1>
                        <div className="container-fluid">
                            {_.uniq(questions).map((question, index) => (
                                <div key={index} className="row">
                                    <div className="col-md-5 col-12">
                                        <h2 className="body-md">{question}</h2>
                                    </div>
                                    <div className="col">
                                        <textarea
                                            value={auditorAnswers[index] || ""}
                                            onChange={(e) =>
                                                handleInputChange(index, e.target.value)
                                            }
                                            onBlur={handleInputBlur}
                                            disabled={isReadOnly}
                                            maxLength={charLimit}
                                            className="w-100 py-2 px-3"
                                        ></textarea>
                                    </div>

                                    <Divider className="my-3" />
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </QuestionsContainer>
    );
};

AuditQuestions.propTypes = {
    questions: PropTypes.array.isRequired,
    auditorAnswers: PropTypes.array.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
};
