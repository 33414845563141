const hasObjectKey = (key, dictionary) => {
    if (!dictionary) return false;

    return key in dictionary;
};

export const isFeatureSwitchActive = (flagName) => {
    const hasSwitch = hasObjectKey(flagName, window.waffle?.SWITCHES);
    if (!hasSwitch) {
        return false;
    }

    return window.waffle.switch_is_active(flagName);
};
