import React, {useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link, NavLink, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {useCurrentUser} from "Models/User/useCurrentUser";
import {UserPermissions} from "Models/User/UserUtils";
import "./TopMenu.styles.jsx";
import {IconButton, ListItemIcon, MenuItem, Slide, Tooltip, useScrollTrigger} from "@mui/material";
import GSPSProfileAvatar from "./GSPSProfileAvatar";
import {TopMenuContainer} from "./TopMenu.styles.jsx";
import GSPSAccountNavActions from "./GSPSAccountNavActions.jsx";
import LogoutIcon from "@mui/icons-material/Logout";

const HideOnScroll = (props) => {
    const {children, window} = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

export const TopMenu = ({resetCurrentUserSession}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const openAccountMenu = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const currentUser = useCurrentUser();
    const isAdmin = UserPermissions.useIsAdmin();
    const isSuperAdmin = UserPermissions.useIsSuperAdmin();
    const handleUserLogout = () => {
        resetCurrentUserSession(navigate);
    };

    const handleOpenAccountMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const checkConditions = (conditions, isAdmin, currentUser, isSuperAdmin) => {
        if (!conditions || conditions.length === 0) return true;
        return conditions.every((condition) => {
            switch (condition) {
                case "isAdmin":
                    return isAdmin;
                case "isSuperAdmin":
                    return isSuperAdmin;
                case "isNotAdmin":
                    return !isAdmin;
                case "isSubscriber":
                    return currentUser.subscriber;
                default:
                    return true;
            }
        });
    };


    const navContent = {
        navLinks: [

            {
                "title": "Dashboard",
                "url": "/dashboard",
                "conditions": [],
            },
            {
                "title": "Locations",
                "url": "/locations",
                "conditions": ["isAdmin"],
            },
            {
                "title": "Accounts",
                "url": "/accounts",
                "conditions": ["isAdmin"],
            },
            {
                "title": "Users",
                "url": "/users",
                "conditions": ["isAdmin"],
            },
            {
                "title": "Subscribers",
                "url": "/subscribers",
                "conditions": ["isSuperAdmin"],
            },
            {
                "title": "Support",
                "url": "mailto:support@sitevisionpro.com",
                "conditions": [],
            },
        ],
        accountActions: [
            <MenuItem key={1} onClick={handleUserLogout} className="py-4">
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Sign out
            </MenuItem>,
        ],
    };

    const displayNavLinks = () => {
        return (
            <>
                {navContent.navLinks.map((navLink, index) => {
                    if (checkConditions(navLink.conditions, isAdmin, currentUser, isSuperAdmin)) {
                        return (
                            <NavLink
                                key={index}
                                className="navLink mx-md-3 mb-4 mb-lg-0 pb-1 menu-link-md"
                                to={navLink.url}
                            >
                                {navLink.title}
                            </NavLink>
                        );
                    }
                    return null;
                })}
            </>
        );
    };


    return (
        <HideOnScroll>
            <TopMenuContainer>
                <Navbar collapseOnSelect expand="lg" className="TopMenu navbar-expand-lg py-2 z-3"
                    data-bs-theme="light">
                    <Container>
                        <Navbar.Brand>
                            <Link className="logo my-2" to={"/dashboard"}>
                                <img src="/SVP_dark_bg.png" className="img-fluid" alt="logo" />
                            </Link>
                        </Navbar.Brand>

                        <Navbar.Toggle className="menuToggler p-0" aria-controls="responsive-navbar-nav">
                            <span></span>
                            <span></span>
                            <span></span>
                        </Navbar.Toggle>

                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">

                            <Nav className="justify-content-md-center align-items-md-center">
                                {displayNavLinks()}
                            </Nav>
                            <div className="accountMenu position-relative">
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleOpenAccountMenu}
                                        aria-controls={openAccountMenu ? "account-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openAccountMenu ? "true" : undefined}
                                        className="p-0"
                                    >
                                        <GSPSProfileAvatar width={40} height={40} />
                                    </IconButton>
                                </Tooltip>
                                <div className="mb-4 mb-lg-0">
                                    <GSPSAccountNavActions
                                        open={openAccountMenu}
                                        handleClose={handleClose}
                                        anchorEl={anchorEl}
                                        user={currentUser}
                                        actions={navContent.accountActions}
                                    />
                                </div>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </TopMenuContainer>
        </HideOnScroll>
    );
};

TopMenu.propTypes = {
    resetCurrentUserSession: PropTypes.func.isRequired,
};

HideOnScroll.propTypes = {
    children: PropTypes.node.isRequired,
    window: PropTypes.func,
};

