import React, {useEffect, useState, useRef, useMemo} from "react";
import {toPng} from "html-to-image";
import {makeGetRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {GSPSGoogleMap} from "Components/Map/GSPSGoogleMap";
import {useParams, useSearchParams} from "react-router-dom";
import {AuditMapSelectedIssues} from "Pages/Audits/AuditMapSelectedIssues";
import {AuditMapSelectedLines} from "Pages/Audits/AuditMapSelectedLines";
import {AuditMapSelectedAreas} from "Pages/Audits/AuditMapSelectedAreas";
import {EmailPopup} from "Components/Common/EmailPopup";
import {ReviewerInformation} from "Pages/Audits/AuditReviewer";
import {AuditQuestions} from "Pages/Audits/AuditQuestions";
import {initMediaSelector} from "./AuditMedia";
import {AuditorInformation} from "./AuditorInformation";
import GSPSAnnotationModal from "Components/Map/GSPSAnnotationModal";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {Divider} from "@mui/material";
import {AuditContainer} from "./AuditCreator.styles";
import {filterAndIndexItems} from "Utils/helpers";
import {SVPMapFilter} from "Components/Map/SVPMapFilter";
import {Box} from "@mui/material";
import SVPExportButton from "Components/PDFExporter/SVPExportButton";
import AuditReportPDF from "Pages/Audits/AuditReportPDF";
import {downloadPdf, previewPdf} from "Components/PDFExporter/PDFExporterUtils";
import SVPNavigationSpeedDial from "Components/SpeedDial/SVPNavigationSpeedDial";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPen, faMapLocationDot} from "@fortawesome/free-solid-svg-icons";
import {Info as InfoIcon} from "@mui/icons-material";
import PropTypes from "prop-types";

export const AuditViewer = ({publicAudit = null}) => {
    const {auditID} = useParams();
    const [audit, setAudit] = useState();
    const [searchParams] = useSearchParams();
    const [reviewer, setReviewer] = useState([]);
    const [QuesAns, setQuesAns] = useState([]);
    const [annotationMarkers, setAnnotationMarkers] = useState([]);
    const [annotationLines, setAnnotationLines] = useState([]);
    const [annotationAreas, setAnnotationAreas] = useState([]);
    const [currentLocation, setCurrenLocation] = useState(null);
    const [clickedItem, setClickedItem] = useState(null);
    const [modalItemType, setModalItemType] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [issuesSeveritiesWithColor, setIssuesSeveritiesWithColor] = useState();
    const [isExportNotes, setIsExportNotes] = useState(false);
    const [mapImageForExportPDF, setMapImageForExportPDF] = useState(null);
    const [auditsFilter, setAuditsFilter] = useState({
        "items": [],
        "severity": [],
        "lines": [],
        "areas": [],
        "default": true,
    });
    const mapRef = useRef();

    const iconStyle = {fontSize: "18px"};

    const actions = [
        {
            icon: <FontAwesomeIcon icon={faUserPen} style={iconStyle} />,
            name: "Reviewer Information",
            href: "reviewer",
        },
        {
            icon: <InfoIcon style={iconStyle} />,
            name: "Auditor Information",
            href: "audit-info",
        },
        !!auditID && {
            icon: <FontAwesomeIcon icon={faMapLocationDot} style={iconStyle} />,
            name: "Map",
            href: "map-container",
        },
    ].filter(Boolean);

    const hideEmailPopupFromURL = searchParams.get("report") === "false";
    const endpointToUseAudit = publicAudit || API_AUDITS.audits;
    const isPublicAudit = !!publicAudit;

    const captureMap = () => {
        if (mapRef.current === null) {
            return;
        }
        toPng(mapRef.current, {cacheBust: true})
            .then((dataUrl) => {
                setMapImageForExportPDF(dataUrl);
            })
            .catch((err) => {
                console.error("Failed to capture map as image", err);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            captureMap();
        }, 3000);
        return () => clearTimeout(timer);
    }, [mapRef.current]);

    const exportOptions = [
        {
            onClick: () => {
                mapImageForExportPDF &&
                    previewPdf(<AuditReportPDF
                        audit={audit}
                        mapImage={mapImageForExportPDF}
                        canExportNotes={isExportNotes}
                    />);
            },
            text: "Preview PDF",
        },
        {
            onClick: () => {
                mapImageForExportPDF &&
                    downloadPdf(
                        <AuditReportPDF
                            audit={audit}
                            mapImage={mapImageForExportPDF}
                            canExportNotes={isExportNotes}
                        />,
                        "SiteVisionPro - Report.pdf",
                    );
            },
            text: "Download PDF",
        },
    ];

    useEffect(() => {
        makeGetRequest(`${endpointToUseAudit}${auditID}/`).then(
            (res) => {
                setAudit(res);
                setReviewer([res.reviewer]);
                setQuesAns(res.answers.map((ans) => ans.answer_text));
                setQuestions(res.answers.map((ans) => ans.question_text));
            },
        );
    }, []);

    useEffect(() => {
        if (!isPublicAudit) {
            makeGetRequest(`${API_AUDITS.issuesSeverities}`).then(
                (res) => {
                    setIssuesSeveritiesWithColor(res.results);
                },
            );
        }
    }, []);

    useEffect(() => {
        if (audit) {
            if (audit.audit_issues) {
                const issues = audit.audit_issues.map((issue) => ({
                    ...issue, images: issue.issue_images.length > 0 ? issue.issue_images : [
                        initMediaSelector("issue", annotationMarkers.length, "0"),
                        initMediaSelector("issue", annotationMarkers.length, "1"),
                    ],
                    videos: issue.issue_videos.length > 0 ? issue.issue_videos :
                        [initMediaSelector("issue", annotationMarkers.length, "0")],
                }));
                setAnnotationMarkers(issues);
            }
            if (audit.audit_areas) {
                const areas = audit.audit_areas.map((area) => ({
                    ...area, images: area.area_images.length > 0 ? area.area_images : [
                        initMediaSelector("area", annotationAreas.length, "0"),
                        initMediaSelector("area", annotationAreas.length, "1"),
                    ],
                    videos: area.area_videos.length > 0 ? area.area_videos :
                        [initMediaSelector("area", annotationAreas.length, "0")],
                }));
                setAnnotationAreas(areas);
            }
            if (audit.audit_lines) {
                const lines = audit.audit_lines.map((line) => ({
                    ...line, images: line.line_images.length > 0 ? line.line_images : [
                        initMediaSelector("line", annotationLines.length, "0"),
                        initMediaSelector("line", annotationLines.length, "1"),
                    ],
                    videos: line.line_videos.length > 0 ? line.line_videos :
                        [initMediaSelector("line", annotationLines.length, "0")],
                }));
                setAnnotationLines(lines);
            }
        }
        if (audit?.location?.address) {
            setCurrenLocation({
                "lat": Number(audit?.location?.address.latitude),
                "lng": Number(audit?.location?.address.longitude),
            });
        }
    }, [audit]);

    const onLineClick = (line) => {
        setModalItemType("line");
        setClickedItem(line);
        setIsModalOpen(true);
    };

    const onAreaClick = (Area) => {
        setModalItemType("area");
        setClickedItem(Area);
        setIsModalOpen(true);
    };
    const onIssueClick = (issue) => {
        setModalItemType("issue");
        setClickedItem(issue);
        setIsModalOpen(true);
    };

    const tidySelectedIssues = useMemo(() => {
        return filterAndIndexItems(annotationMarkers, "items", auditsFilter, true);
    }, [annotationMarkers, auditsFilter]);

    const tidySelectedLines = useMemo(() => {
        return filterAndIndexItems(annotationLines, "lines", auditsFilter);
    }, [annotationLines, auditsFilter]);

    const tidySelectedAreas = useMemo(() => {
        return filterAndIndexItems(annotationAreas, "areas", auditsFilter);
    }, [annotationAreas, auditsFilter]);

    return (
        <AuditContainer>
            {!(isPublicAudit || hideEmailPopupFromURL) && <EmailPopup />}

            {audit &&
                <>
                    <GSPSBreadCrumbs staticCrumb={`Audit: ${audit.name}`} />
                    <div className="container">
                        {
                            <SVPExportButton
                                options={exportOptions}
                                isLoading={!mapImageForExportPDF}
                                isExportNotes={isExportNotes}
                                setIsExportNotes={setIsExportNotes}
                                isPublicAudit={isPublicAudit}
                            />
                        }

                        <Box sx={{width: "10%", minWidth: "100px"}}>
                            <SVPMapFilter
                                annotationMarkers={annotationMarkers}
                                annotationLines={annotationLines}
                                annotationAreas={annotationAreas}
                                auditIssues={audit?.audit_issues}
                                auditLines={audit?.audit_lines}
                                auditAreas={audit?.audit_areas}
                                auditsFilter={auditsFilter}
                                setAuditsFilter={setAuditsFilter}
                            />
                        </Box>

                        <div ref={mapRef}>
                            <GSPSGoogleMap
                                audit={audit}
                                currentLocation={currentLocation}
                                annotationAreas={annotationAreas}
                                annotationLines={annotationLines}
                                annotationMarkers={annotationMarkers}
                                onLineClick={onLineClick}
                                onIssueClick={onIssueClick}
                                onAreaClick={onAreaClick}
                                isViewOnly={true}
                                auditsFilter={auditsFilter}
                            />
                        </div>
                        <SVPNavigationSpeedDial actions={actions} />
                        <GSPSAnnotationModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            audit={audit}
                            clickedItem={clickedItem}
                            annotationType={modalItemType}
                            issuesSeverities={issuesSeveritiesWithColor}
                            isViewOnly={true}
                        />
                        {auditsFilter.items &&
                            auditsFilter.items.length > 0 &&
                            auditsFilter.severity && auditsFilter.severity.length > 0 &&
                            <AuditMapSelectedIssues
                                audit={audit}
                                tidySelectedIssues={tidySelectedIssues}
                                isViewOnly={true}
                                isPublicAudit={isPublicAudit}
                            />
                        }
                        {auditsFilter.lines && auditsFilter.lines.length > 0 &&
                            <AuditMapSelectedLines
                                tidySelectedLines={tidySelectedLines}
                                isViewOnly={true}
                                updateElementImages={null}
                                updateElementVideos={null}
                                isPublicAudit={isPublicAudit}
                            />
                        }
                        {auditsFilter.areas && auditsFilter.areas.length > 0 &&
                            <AuditMapSelectedAreas
                                tidySelectedAreas={tidySelectedAreas}
                                isViewOnly={true}
                                isPublicAudit={isPublicAudit}
                            />
                        }
                        <AuditQuestions
                            questions={questions}
                            auditorAnswers={QuesAns}
                            isReadOnly={true}
                        />
                        <AuditorInformation
                            audit={audit}
                            isReadOnly={true}
                            isPublicAudit={isPublicAudit}
                        />
                        {reviewer.length > 0 &&
                            <div>
                                <Divider className="my-4" />

                                <ReviewerInformation
                                    audit={audit}
                                    users={reviewer}
                                    readonly={true}
                                    isPublicRecord={isPublicAudit}
                                />
                            </div>
                        }
                    </div>
                </>
            }
        </AuditContainer>
    );
};

AuditViewer.propTypes = {
    publicAudit: PropTypes.string,
};
