import React from "react";
import {Tooltip, OverlayTrigger} from "react-bootstrap";
import {FaInfoCircle} from "react-icons/fa";
import PropTypes from "prop-types";

export const SVPToolTipIcon = ({
    message,
    fontSize,
    placement = "top", // "top" | "right" | "bottom" | "left"
    rightMargin = "0px",
}) => {
    return <>
        <span style={{marginRight: rightMargin}}></span>
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip id="tooltip-right" style={{fontSize: "12px"}}>{message}</Tooltip>}
        >
            <span>
                <FaInfoCircle
                    style={{
                        cursor: "pointer",
                        fontSize,
                    }}
                    color="green"
                />
            </span>
        </OverlayTrigger>
    </>;
};

SVPToolTipIcon.propTypes = {
    message: PropTypes.string.isRequired,
    fontSize: PropTypes.string.isRequired,
    placement: PropTypes.string,
    rightMargin: PropTypes.string,
};
