import {Form} from "react-bootstrap";
import styled from "styled-components";

export const GSPSToolbarSelectFieldContainer = styled.div`
    width: 100%;
    text-transform: capitalize;
    margin: 0.5rem;
    border-radius: 0.05rem;
    border-color: ${({theme}) => theme.color.border};
    background: ${({theme}) => theme.color.primary};
    padding: 0.2rem;  /* Added padding */
    justify-content: center;
    align-items: center;
    text-align: center;
    & input{
        font-size: 14px;
        font-weight: 400;
    }
    input::selection {
        background-color: transparent;
        color: ${({theme}) => theme.color.textOnBg};
    }
`;

export const GSPSToolbarSelectFieldStyle = styled(Form.Select)`
    border-radius: 0.625rem;
    width: 10rem;
    padding: 0.75rem 1rem;
    margin: 0.2rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    min-width: 6rem;
`;
