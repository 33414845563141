import React, {useState, useEffect, useImperativeHandle, forwardRef} from "react";
import PropTypes from "prop-types";
import {GSPSToolbarSelectField} from "Components/Common/GSPSToolbarSelectField";
import {insertNotification} from "Utils/NotificationsUtils";

export const SEVERITY_LABEL = "Item Severity";

export const SVPMapFilter = forwardRef(({
    annotationMarkers,
    annotationLines,
    annotationAreas,
    auditIssues,
    auditLines,
    auditAreas,
    auditsFilter,
    setAuditsFilter,
}, ref) => {
    const [auditFilterOptionsList, setAuditFilterOptionsList] = useState([]);

    // Expose functions for managing filters to the parent via the ref
    useImperativeHandle(ref, () => ({
        callReplaceTypeItemFilter(prevTypeName, newTypeName, annotationType) {
            replaceTypeItemFilter(prevTypeName, newTypeName, annotationType);
        },
        callAddTypeToAuditsFilterIfNotExists(annotationType, itemTypeName) {
            addTypeToAuditsFilterIfNotExists(annotationType, itemTypeName);
        },
        callDeleteTypeFromAuditsFilterIfExists(annotationType, itemTypeName) {
            deleteTypeFromAuditsFilterIfExists(annotationType, itemTypeName);
        },
        callResetAuditTypesOptions(prevTypeName, newTypeName, annotationType) {
            resetAuditTypesOptions(prevTypeName, newTypeName, annotationType);
        },
    }));

    // Populate the filter options list based on the provided audit data
    useEffect(() => {
        if (auditIssues) {
            setAuditFilterOptionsList((prev) => {
                const isTypesAdded = prev.some((option) => option.label === "Items");
                const isSeveritiesAddded = prev.some((option) => option.label === SEVERITY_LABEL);
                const newAuditsFilter = [
                    ...prev,
                    ...(!isTypesAdded ?
                        [{checked: true, label: "Items", children: extractAuditTypes(auditIssues)}] : []),
                    ...(!isSeveritiesAddded ?
                        [{checked: true, label: SEVERITY_LABEL, children: extractSeverities(auditIssues)}] : []),
                ];
                return newAuditsFilter;
            });
        }
        if (auditLines) {
            setAuditFilterOptionsList((prev) => {
                const isAdded = prev.some((option) => option.label === "Lines");
                return isAdded ?
                    prev : [...prev, {checked: true, label: "Lines", children: extractAuditTypes(auditLines)}];
            });
        }
        if (auditAreas) {
            setAuditFilterOptionsList((prev) => {
                const isAdded = prev.some((option) => option.label === "Areas");
                return isAdded ?
                    prev : [...prev, {checked: true, label: "Areas", children: extractAuditTypes(auditAreas)}];
            });
        }
    }, [auditIssues, auditLines, auditAreas]);

    // Update severity filter options when annotationMarkers change
    useEffect(() => {
        if (annotationMarkers) {
            setAuditFilterOptionsList((prev) => {
                const severityOptionIndex = prev.findIndex((option) => option.label === SEVERITY_LABEL);
                const severities = extractSeverities(annotationMarkers);

                if (severityOptionIndex !== -1) {
                    const updatedOptions = [...prev];
                    updatedOptions[severityOptionIndex] = {
                        ...updatedOptions[severityOptionIndex],
                        children: severities,
                    };
                    return updatedOptions;
                } else {
                    return [
                        ...prev,
                        {checked: true, label: SEVERITY_LABEL, children: severities},
                    ];
                }
            });
        }
    }, [annotationMarkers]);

    // Sync the audit filters with the selected options
    useEffect(() => {
        const updatedAuditsFilter = {
            items: getCheckedLabels(auditFilterOptionsList, "Items"),
            severity: getCheckedLabels(auditFilterOptionsList, SEVERITY_LABEL),
            lines: getCheckedLabels(auditFilterOptionsList, "Lines"),
            areas: getCheckedLabels(auditFilterOptionsList, "Areas"),
        };

        setAuditsFilter(updatedAuditsFilter);
    }, [auditFilterOptionsList]);

    // Utility to get checked labels for a specific filter category
    const getCheckedLabels = (optionsList, label) => {
        const option = optionsList.find((opt) => opt.label.toLowerCase() === label.toLowerCase());
        return option ? option.children.filter((child) => child.checked).map((child) => child.label) : [];
    };

    // Update the audit options list with new data or updates
    const updateAuditFilterOptionsList = (auditFilterOptionsList, value) => {
        const updatedList = auditFilterOptionsList.map((option) => {
            const key = option.label.toLowerCase();
            const correspondingValue = value[key] ?? [];
            if (correspondingValue) {
                option.checked = correspondingValue?.length > 0;
                option.children = option.children.map((child) => ({
                    ...child,
                    checked: correspondingValue.includes(child.label),
                }));
            }
            return option;
        });
        return updatedList;
    };

    // Extract audit types from the provided items
    const extractAuditTypes = (items) => {
        const auditTypes = items
            .map((item) => ({label: item.audit_type_item.name?.trim(), checked: true}))
            .filter((obj, index, self) =>
                self.findIndex((element) => element.label === obj.label) === index,
            );
        return auditTypes;
    };

    // Extract audit severities from the provided items
    const extractSeverities = (items) => {
        const severities = items
            .map((item) => ({label: item.severity_object.name?.trim(), checked: true}))
            .filter((obj, index, self) =>
                self.findIndex((element) => element.label === obj.label) === index,
            );
        return severities;
    };

    // Add a type to the audits filter if it doesn't exist
    const addTypeToAuditsFilterIfNotExists = (annotationType, typeName) => {
        const auditTypes = getAnnotationAuditTypes(annotationType);
        if (auditTypes && !auditTypes.children.some((child) => child.label === typeName?.trim())) {
            setAuditFilterOptionsList((prevState) => {
                const updatedChildren = [...auditTypes.children, {label: typeName?.trim(), checked: true}];
                const updatedAuditFilterOptionsList = prevState.map((option) => {
                    if (option.label === auditTypes.label) {
                        return {...option, checked: true, children: updatedChildren};
                    }
                    return option;
                });
                return updatedAuditFilterOptionsList;
            });
        }
    };

    // Remove a type from the audits filter if it exists
    const deleteTypeFromAuditsFilterIfExists = (annotationType, typeName) => {
        const auditTypes = getAnnotationAuditTypes(annotationType);
        const annotationMap = {
            Items: annotationMarkers,
            Severity: annotationMarkers,
            Lines: annotationLines,
            Areas: annotationAreas,
        };
        const annotationArray = annotationMap[auditTypes.label];

        const auditTypeOccurs = annotationArray?.filter(
            (item) => {
                if (auditTypes?.label === SEVERITY_LABEL) {
                    return item.severity_object.name?.trim() === typeName.trim();
                }
                return item.audit_type_item.name?.trim() === typeName.trim();
            },
        )?.length;
        setAuditFilterOptionsList((prevState) => {
            return prevState.map((option) => {
                if (option.label !== auditTypes.label) return option;

                if (auditTypeOccurs === 1) {
                    const updatedChildren = option.children.filter(
                        (child) => child.label !== typeName,
                    );
                    return {...option, checked: true, children: updatedChildren};
                }

                return option;
            });
        });
    };

    // Replace a type in the audits filter
    const replaceTypeItemFilter = (prevTypeName, newTypeName, annotationType) => {
        addTypeToAuditsFilterIfNotExists(annotationType, newTypeName);
        deleteTypeFromAuditsFilterIfExists(annotationType, prevTypeName?.trim());
    };

    // Handle filter selection updates
    const handleFilterSelection = (value) => {
        try {
            const updatedAuditFilterOptionsList = updateAuditFilterOptionsList(auditFilterOptionsList, value);
            setAuditFilterOptionsList(updatedAuditFilterOptionsList);
            setAuditsFilter(value);
        } catch (e) {
            insertNotification("Error", `can't filter. Reason: ${e.message}`, "error");
        }
    };

    // Reset the options for a specific annotation type
    const resetAuditTypesOptions = (annotationType) => {
        setAuditFilterOptionsList((currentAuditFilterOptionsList) => currentAuditFilterOptionsList.map((option) => {
            if (option.label === annotationType) {
                return {
                    ...option,
                    children: [],
                };
            }
            return {
                ...option,
                children: [...option.children],
            };
        }));
    };

    // Get options for a specific annotation type
    const getAnnotationAuditTypes = (annotationType) => {
        return auditFilterOptionsList.find((option) => option.label === annotationType);
    };

    return (
        <GSPSToolbarSelectField
            inputID="filter_audits"
            selectableOptions={auditFilterOptionsList}
            onChangeCB={(value) => {
                handleFilterSelection(value);
            }}
            renderedElementType={"filter"}
            updatedAuditFilter={auditsFilter}
        />
    );
});

SVPMapFilter.displayName = "SVPMapFilter";

SVPMapFilter.propTypes = {
    annotationMarkers: PropTypes.array,
    annotationLines: PropTypes.array,
    annotationAreas: PropTypes.array,
    auditIssues: PropTypes.array,
    auditLines: PropTypes.array,
    auditAreas: PropTypes.array,
    auditsFilter: PropTypes.object.isRequired,
    setAuditsFilter: PropTypes.func.isRequired,
};
