import React, {useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {GSPSCancelButton} from "Components/Common/GSPSCancelButton";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {LocationForm} from "Components/Locations/LocationForm";
import {useUpdateLocationMutation} from "api/LocationTableAPI";
import {insertNotification} from "Utils/NotificationsUtils";
import {GSPSPageWrapper, GSPSPopUpHeader, GSPSPopUpFooter} from "Components/Common/GSPSStyledContainers.styles";
import {makeGetRequest} from "Services/ServicesUtils";
import PropTypes from "prop-types";

export const Location = ({show, setShow, locationID}) => {
    const {
        handleSubmit,
        register,
        setValue,
        reset,
        formState: {errors},
    } = useForm();
    const [location, setLocation] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [updateLocation] = useUpdateLocationMutation();

    useEffect(() => {
        makeGetRequest(`${API_LOCATIONS.locations}${locationID}/`).then((res) => {
            setLocation(res);
            reset(res);
        });
    }, [reset, locationID]);

    const onSubmit = async (values) => {
        const updateData = {
            ...values,
            account_id: values.location_account_id,
        };
        setIsSubmitting(true);

        try {
            const updatedLocation = await updateLocation({
                id: locationID,
                updatedItem: {...updateData},
            }).unwrap();

            insertNotification(
                "Success",
                `Account ${updatedLocation.account.name} has been updated ..`,
                "success",
            );
            setShow(false);
        } catch (error) {
            insertNotification(
                "Error",
                `Can't update location: ${error.data.detail || error.message}`,
                "error",
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <GSPSPageWrapper className="show" role="dialog"
                show={show}
                centered={true}
                size={"lg"}
                container={document.querySelector("#root .sc-bbSYpP")}
            >
                <GSPSPopUpHeader className="subtitle-lg">
                    Location Information
                </GSPSPopUpHeader>
                {location &&
                    <>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <LocationForm
                                location={location}
                                register={register}
                                errors={errors}
                                setValue={setValue}
                            />
                            <GSPSPopUpFooter>
                                <GSPSCancelButton
                                    isDisabled={false}
                                    controlSize={2}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setShow(false);
                                    }}
                                    buttonText={"Cancel"} />
                                <GSPSSubmitButton
                                    isLoading={isSubmitting}
                                    isDisabled={false}
                                    controlSize={2}
                                    offset={0}
                                    buttonText={"Save"} />
                            </GSPSPopUpFooter>
                        </Form>
                    </>
                }
            </GSPSPageWrapper>

        </>
    );
};
Location.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    locationID: PropTypes.number,
};

