import {Col, Form, Row} from "react-bootstrap";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {AddressForm} from "Components/Common/AddressForm";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";
import {GSPSFormImageInput} from "Components/Common/GSPSFormImageInput";
import React from "react";
import {useForm} from "react-hook-form";
import {GSPSCancelButton} from "Components/Common/GSPSCancelButton";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import PropTypes from "prop-types";
import {GSPSInputContainer, GSPSPopUpFooter} from "Components/Common/GSPSStyledContainers.styles";
import {useNavigate} from "react-router-dom";
import {GSPSEnableDisableSwitch} from "Components/Common/GSPSEnableDisableSwitch";

export const SubscriberFormFields = ({
    onSubmit,
    isSubmitting,
    setShow = null,
    defaultUnits = null,
    industries = null,
    subscriber = null,
    setIsDeleteLogo = null,
}) => {
    const {handleSubmit, register, setValue, formState: {isDirty, touched, errors}} = useForm();
    const navigate = useNavigate();

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xs={10} md={6}>
                    <GSPSInputContainer>
                        <GSPSLabelledInput
                            id="subscriber_name"
                            controlType={"input"}
                            value={subscriber?.name}
                            labelName={"Name"}
                            isRequired={true}
                            register={register}
                            errors={errors}
                        />
                        <AddressForm
                            id="address"
                            register={register}
                            errors={errors}
                            caller={subscriber}
                            setValue={setValue}
                        />
                        <GSPSLabelledInput
                            id="website"
                            controlType={"input"}
                            value={subscriber?.website}
                            labelName={"Website"}
                            register={register}
                            errors={errors}
                        />
                        <GSPSLabelledInput
                            id="phone"
                            controlType={"input"}
                            value={subscriber?.phone}
                            labelName={"Phone"}
                            register={register}
                            errors={errors}
                            isPhone={true}
                        />
                        {industries &&
                            <GSPSLabelledSelectField
                                labelName={"Industry"}
                                defaultValue={subscriber?.industry}
                                inputID={"industry"}
                                selectableOptions={industries}
                                register={register}
                                errors={errors}
                            />
                        }
                    </GSPSInputContainer>
                </Col>

                <Col xs={10} md={6}>
                    {defaultUnits &&
                        <GSPSInputContainer>
                            <GSPSLabelledSelectField
                                labelName={"Default Units"}
                                defaultValue={subscriber?.default_unit}
                                inputID={"default_unit"}
                                selectableOptions={defaultUnits}
                                register={register}
                                errors={errors}
                                isRequired={true}
                            />
                        </GSPSInputContainer>
                    }
                    {subscriber &&
                        <>
                            <GSPSInputContainer>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <GSPSLabelledInput
                                        id="max_users_count"
                                        controlType={"input"}
                                        value={subscriber.max_users_count}
                                        labelName={"Max Users Count"}
                                        register={register}
                                        errors={errors}
                                        tooltipMessage={"Leave blank for unlimited users"}
                                    />
                                </div>
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                <label className="label-sm mt-2">
                                    Active Users Count:
                                </label>
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                {subscriber.users_count}
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                <label className="label-sm mt-2">
                                    Audits Count:
                                </label>
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                {subscriber.audits_count}
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                <label className="label-sm mt-2">
                                    Default Account:
                                </label>
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                [{subscriber.default_account?.id}] {subscriber.default_account?.name}
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                <label className="label-sm mt-2">
                                    Default User:
                                </label>
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                [{subscriber.default_user?.id}] {subscriber.default_user?.full_name}
                            </GSPSInputContainer>
                            <GSPSInputContainer>
                                {subscriber.default_user?.email}
                            </GSPSInputContainer>
                            <GSPSEnableDisableSwitch
                                inputId={"is_active"}
                                defaultValue={subscriber.is_active}
                                register={register}
                            />
                            <GSPSInputContainer>
                                <GSPSFormImageInput
                                    id={"logo"}
                                    labelName={"Add Logo"}
                                    labelSize={5}
                                    controlSize={5}
                                    imageContainerWidthPx={290}
                                    imageContainerHeightPx={175}
                                    setValue={setValue}
                                    value={subscriber?.logo}
                                    currentImageSrc={subscriber?.logo}
                                    handleDeleteImage={() => setIsDeleteLogo(true)}
                                    setHasLogo={() => {}}
                                />
                            </GSPSInputContainer>
                        </>
                    }
                    {!subscriber && <>
                        <GSPSInputContainer>
                            <GSPSLabelledInput
                                id="email"
                                controlType={"input"}
                                labelName={"Email"}
                                isRequired={true}
                                register={register}
                                errors={errors}
                            />
                        </GSPSInputContainer>
                        <GSPSInputContainer>
                            <GSPSLabelledInput
                                id="first_name"
                                controlType={"input"}
                                labelName={"First Name"}
                                isRequired={true}
                                register={register}
                                errors={errors}
                            />
                        </GSPSInputContainer>
                        <GSPSInputContainer>
                            <GSPSLabelledInput
                                id="last_name"
                                controlType={"input"}
                                labelName={"Last Name"}
                                isRequired={true}
                                register={register}
                                errors={errors}
                            />
                        </GSPSInputContainer>
                    </>}
                </Col>
            </Row>
            <GSPSPopUpFooter>
                <GSPSCancelButton
                    controlSize={0}
                    offset={0}
                    buttonText={"Cancel"}
                    secondaryBtn={true}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate("/subscribers");
                        setShow && setShow(false);
                    }}
                />
                <GSPSSubmitButton
                    isDisabled={(!isDirty && touched) || errors.zip?.message.length > 0 || isSubmitting}
                    isLoading={isSubmitting}
                    controlSize={2}
                    buttonText={"Save"}
                />
            </GSPSPopUpFooter>
        </Form>
    );
};

SubscriberFormFields.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    setShow: PropTypes.func,
    subscriber: PropTypes.object,
    defaultUnits: PropTypes.string,
    industries: PropTypes.string,
    setIsDeleteLogo: PropTypes.func,
};
