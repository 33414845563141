export const SeverityLevelID = {
    LOW: 2,
    NOT_APPLICABLE: 4,
};

export const AnnotationType = {
    ISSUE: "issue",
    LINE: "line",
    AREA: "area",
};

export const AuditStatusID = {
    IN_PROGRESS: 1,
    IN_REVIEW: 2,
};

export const AnnotationShapeIDMap = {
    ISSUE: {id: 1, query: "item"},
    LINE: {id: 2, query: AnnotationType.LINE},
    AREA: {id: 3, query: AnnotationType.AREA},
};
