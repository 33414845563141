import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useForm} from "react-hook-form";
import {Col, Container, Form, Row} from "react-bootstrap";
import {makeFormDataUpdateRequest, makeUpdateRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {compressImage, compressVideo} from "Utils/helpers";
import {GSPSLabelledSelectFieldWithIcon} from "Components/Common/GSPSLabelledSelectFieldWithIcon";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSTextArea} from "Components/Common/GSPSTextAreaInput";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {GSPSPageWrapper, GSPSPopUpFooter, GSPSPopUpHeader} from "../Common/GSPSStyledContainers.styles";
import {GSPSCancelButton} from "../Common/GSPSCancelButton";
import {FFmpeg} from "@ffmpeg/ffmpeg";
import {AnnotationType} from "Models/Audit/AuditConstants";
import {SEVERITY_LABEL} from "Components/Map/SVPMapFilter";
import {MediaViewer} from "Pages/Audits/MediaViewer";

const ffmpeg = new FFmpeg();

const GSPSAnnotationModal = ({
    isOpen,
    onClose,
    audit,
    clickedItem,
    annotationType,
    issuesSeverities,
    deleteMedia,
    updateItem,
    typeItems,
    replaceTypeItemFilter,
    isViewOnly = false,
}) => {
    const videoCompressionFeature = false;
    const {handleSubmit, register, setValue, reset, formState: {isDirty, touched, errors}} = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [isVideoCompressed, setIsVideoCompressed] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [availableTypeItems, setAvailableTypeItems] = useState([]);
    const [item, setItem] = useState(clickedItem);
    const [deletedMedia, setDeletedMedia] = useState([]);

    const auditsFilterMap = {
        area: "Areas",
        issue: "Items",
        line: "Lines",
    };

    const updateElementMedia = (index, mediaValue, elementType, mediaType) => {
        setItem((prev) => {
            const newData = {...prev};
            const list = newData[annotationType + "_" + mediaType] || [];
            if (index >= list.length) {
                const newMedia = {};
                if (mediaType === "videos") {
                    newMedia.video_value = mediaValue[0];
                } else {
                    newMedia.mediaValue = mediaValue[0];
                }
                newData[annotationType + "_" + mediaType] = [...list, newMedia];
            } else if (index < list.length) {
                newData[annotationType + "_" + mediaType] = list.map((img, i) => {
                    if (i === index) {
                        if (mediaType === "videos") {
                            return {...img, video_value: mediaValue[0]};
                        }
                        return {...img, mediaValue: mediaValue[0]};
                    }
                    return img;
                });
            }
            return newData;
        });
    };

    const updateElementImages = (rowId, newRowImages, elementType) => {
        updateElementMedia(rowId, newRowImages, elementType, "images");
    };
    const updateElementVideos = (rowId, newRowImages, elementType) => {
        updateElementMedia(rowId, newRowImages, elementType, "videos");
    };

    const handleDelete = (media, index, mediaType) => {
        setDeletedMedia((prev) => [...prev, media]);
        setItem((prev) => {
            const newData = {...prev};

            const list = newData[annotationType + "_" + mediaType + "s"] || [];
            if (index < list.length) {
                newData[annotationType + "_" + mediaType + "s"] = list.filter((_, i) => i !== index);
            }
            return newData;
        });
    };


    const filterItems = useCallback((annotationType, auditTypeId) => {
        const filtered = typeItems.filter((item) =>
            item.annotation_type === annotationType && item.audit_type.id === auditTypeId).map((item) => ({
            id: item.id,
            name: item.name,
            color_code: item.color,
        }));
        setAvailableTypeItems(filtered);
    }, [typeItems]);

    useEffect(() => {
        if (clickedItem) {
            if (!isViewOnly) {
                if (annotationType === AnnotationType.ISSUE) {
                    setValue("issue_severity", clickedItem.severity);
                }
                setValue("audit_type_item_id", clickedItem.audit_type_item.id);
                setValue("description", clickedItem.description);
                setValue("notes", clickedItem.notes);
            } else {
                setAvailableTypeItems([{
                    id: clickedItem.audit_type_item.id,
                    name: clickedItem.audit_type_item.name,
                    color_code: clickedItem.audit_type_item.color,
                }]);
                setValue("audit_type_item_id", clickedItem.audit_type_item.id);
            }
        }
    }, [clickedItem, availableTypeItems, issuesSeverities, annotationType, isViewOnly, setValue]);

    useEffect(() => {
        setItem(clickedItem);
        if (!isViewOnly) {
            filterItems(annotationType, clickedItem?.audit_type_item?.audit_type?.id);
        }
    }, [clickedItem, annotationType, isViewOnly, filterItems]);

    if (!isOpen && clickedItem?.id === item?.id) return null;

    const onSubmit = async (values) => {
        try {
            setIsSubmitting(true);
            await submitForm(values);
            onClose();
        } catch (e) {
            insertNotification("Error", `Can't add audit ${e}. Reason: create the audit first`, "error");
        } finally {
            setIsSubmitting(false);
        }
    };

    const HandleClose = () => {
        setDeletedMedia([]);
        setItem(clickedItem);
        onClose();
        reset();
    };

    const submitForm = async (values) => {
        const addedAuditTypeItem = typeItems.find((item) => item.id === values.audit_type_item_id);
        if (addedAuditTypeItem && addedAuditTypeItem.name !== clickedItem.audit_type_item.name) {
            replaceTypeItemFilter(clickedItem.audit_type_item.name,
                addedAuditTypeItem.name,
                auditsFilterMap[annotationType]);
        }

        await deleteMedia(deletedMedia);
        setDeletedMedia([]);

        await submitImages(clickedItem.id);
        await submitVideos(clickedItem.id);

        const body = {
            ...clickedItem,
            notes: values.notes,
            location: audit.location.id,
            audit_type_item_id: clickedItem.audit_type_item.id,
            description: values.description,
        };

        body.severity = values.issue_severity ?? clickedItem.severity;

        if (values.length_feet) {
            body.length_feet = values.length_feet;
        } else if (values.area_feet) {
            body.area_feet = values.area_feet;
        }

        if (annotationType === AnnotationType.ISSUE) {
            const newSeverity = issuesSeverities.find((item) => item.id === values.issue_severity);
            if (newSeverity?.name !== clickedItem.severity_object?.name) {
                replaceTypeItemFilter(clickedItem.severity_object?.name, newSeverity?.name, SEVERITY_LABEL);
            }
        }

        body.audit_type_item_id = values.audit_type_item_id ?? clickedItem.audit_type_item.id;
        const endpoint = {area: "auditArea", issue: "auditIssue", line: "auditLine"};

        await makeUpdateRequest(`${API_AUDITS[endpoint[annotationType]]}${clickedItem.id}/`, body)
            .then(async (jsonRes) => {
                insertNotification("Success", `Audit ${annotationType} has been updated`, "success");
                updateItem(clickedItem.id, jsonRes, annotationType);
            }).catch((error) => {
                insertNotification("Error", `Can't update audit ${annotationType}. Reason: ${error.message}`, "error");
            });
    };

    const submitImages = async (auditElementId) => {
        const formData = new FormData();

        const images = item[`${annotationType}_images`] || [];
        const highestSeq = images.reduce((max, img) => Math.max(max, img.image_sequence || 0), 0);

        for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (image.mediaValue) {
                const compressedImage = await compressImage(image.mediaValue);

                const sequenceNumber = image.image_sequence ?? (highestSeq + i + 1);

                formData.append("image", compressedImage);
                formData.append("image_sequence", sequenceNumber);
                formData.append("image_value", compressedImage);
                formData.append("element_type", "compressedImage");
            }
        }

        // Submit the FormData
        await makeFormDataUpdateRequest(
            API_AUDITS.auditElementImage(annotationType + "s", auditElementId),
            formData,
        ).then(async () => { }).catch((error) => {
            insertNotification(
                "Error", `Can't update Images ${annotationType}. Reason: ${error.message}`, "error",
            );
        });
    };

    const submitVideos = async (auditElementId) => {
        if (item[`${annotationType}_videos`].length < 1) {
            return;
        }

        const formData = new FormData();
        await ffmpeg.load();

        for (let i = 0; i < item[`${annotationType}_videos`].length; i++) {
            const video = item[`${annotationType}_videos`][i];
            let videoValue = video.video_value;
            if (!videoValue || typeof videoValue === "string" || !(videoValue instanceof File)) {
                continue;
            }

            if (videoCompressionFeature) {
                setLoadingProgress(0);
                setLoadingVideo(true);
                videoValue = await compressVideo(videoValue, ffmpeg, setLoadingProgress);
                setIsVideoCompressed(true);
            }
            formData.append("video_sequence", i);
            formData.append("video_value", videoValue);
            formData.append("element_type", "video");
        }

        await makeFormDataUpdateRequest(API_AUDITS.auditElementVideo(annotationType + "s", auditElementId), formData)
            .then((jsonRes) => jsonRes)
            .catch((error) => {
                error.then((message) => {
                    insertNotification("Error", message.detail, "error");
                });
            });
        setLoadingVideo(false);
        setIsVideoCompressed(false);
    };

    function renderImagesAndVideos() {
        return <MediaViewer
            isViewOnly={isViewOnly}
            handleDelete={handleDelete}
            item={item}
            annotationType={annotationType}
            updateElementImages={updateElementImages}
            updateElementVideos={updateElementVideos}
            loadingVideo={loadingVideo}
            loadingProgress={loadingProgress}
            isVideoCompressed={isVideoCompressed}
            videoCompressionFeature={videoCompressionFeature}
        />;
    }

    function renderDimensionInfo(inputID, inputVal, inputUnit) {
        return <>
            <GSPSLabelledInput
                controlType={"input"}
                id={inputID}
                labelName={`Dimensions (${inputUnit})`}
                errors={errors}
                register={register}
                isDisabled={isViewOnly}
                value={Number(inputVal).toFixed(2)}
            />
        </>;
    }

    return (
        <GSPSPageWrapper className="show" role="dialog"
            centered={true}
            backdrop="static"
            onHide={onClose}
            show={isOpen}
            size={"lg"}
            container={document.querySelector("#root .App")}
        >
            <GSPSPopUpHeader>
                <h5 className="subtitle-md">Edit {annotationType}</h5>
            </GSPSPopUpHeader>
            <Form id="issueForm" onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <Row>
                        {issuesSeverities && (
                            <Col xs={10} md={6}>
                                <GSPSLabelledSelectFieldWithIcon
                                    labelName={"Severity"}
                                    inputID={"issue_severity"}
                                    selectableOptions={issuesSeverities}
                                    isRequired={true}
                                    isDisabled={isViewOnly}
                                    defaultValue={clickedItem?.severity}
                                    register={register}
                                />
                            </Col>
                        )}
                        <Col xs={10} md={6}>
                            {(availableTypeItems.length >= 1) &&
                                <GSPSLabelledSelectFieldWithIcon
                                    inputID={"audit_type_item_id"}
                                    labelName={"Audit Type Item"}
                                    errors={errors}
                                    selectableOptions={availableTypeItems}
                                    register={register}
                                    isDisabled={isViewOnly}
                                    defaultValue={clickedItem.audit_type_item?.id}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={10} md={6}>
                            <>
                                {annotationType === AnnotationType.LINE && renderDimensionInfo(
                                    "length_feet",
                                    clickedItem.length_feet,
                                    "ft",
                                )}
                                {annotationType === AnnotationType.AREA && renderDimensionInfo(
                                    "area_feet",
                                    clickedItem.area_feet,
                                    "ft²",
                                )}
                                <GSPSTextArea
                                    value={clickedItem?.description}
                                    labelName={"Custom Description"}
                                    id={"description"}
                                    controlType={"input"}
                                    isRequired={false}
                                    register={register}
                                    rows={7}
                                    errors={errors}
                                    isDisabled={isViewOnly}
                                    setValue={setValue}
                                />
                                <GSPSTextArea
                                    value={clickedItem?.notes}
                                    labelName={"Notes"}
                                    id={"notes"}
                                    controlType={"input"}
                                    isRequired={false}
                                    register={register}
                                    rows={7}
                                    errors={errors}
                                    isDisabled={isViewOnly}
                                    setValue={setValue}
                                />
                            </>
                        </Col>
                        {item &&
                            annotationType &&
                            item[`${annotationType}_images`] &&
                            clickedItem?.id === item?.id && renderImagesAndVideos()}
                    </Row>
                </Container>
            </Form>
            <GSPSPopUpFooter>
                <GSPSCancelButton
                    onClick={HandleClose}
                    isDisabled={isSubmitting}
                    controlSize={2}
                    offset={0}
                    buttonText={"Cancel"}
                    form={"issueForm"}
                />
                {!isViewOnly && (
                    <>
                        <GSPSSubmitButton
                            isDisabled={
                                (!isDirty && touched) ||
                                errors.zip?.message.length > 0 ||
                                isSubmitting
                            }
                            isLoading={isSubmitting}
                            controlSize={2}
                            offset={0}
                            buttonText={"Save"}
                            onClick={handleSubmit}
                            form={"issueForm"}
                        />
                    </>
                )}
            </GSPSPopUpFooter>
        </GSPSPageWrapper>
    );
};

GSPSAnnotationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    clickedItem: PropTypes.object,
    isViewOnly: PropTypes.bool,
    audit: PropTypes.object,
    issuesSeverities: PropTypes.array,
    typeItems: PropTypes.array,
    deleteMedia: PropTypes.func,
    updateItem: PropTypes.func,
    annotationType: PropTypes.oneOf([AnnotationType.ISSUE, AnnotationType.AREA, AnnotationType.LINE]),
    replaceTypeItemFilter: PropTypes.func,
};

export default GSPSAnnotationModal;
