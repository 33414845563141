import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {API_USERS} from "Services/Endpoints";
import {makeUnauthenticatedPostRequest} from "Services/ServicesUtils";
import {Row} from "react-bootstrap";
import {FormContainer, CustomDivWidth67, LoginForm, LoginFormGroup} from "Pages/Login/Login.styles";
import {GSPSErrorMessage} from "Components/Common/GSPSErrorMessage";
import {GSPSLabelledInput, PatternType} from "Components/Common/GSPSLabelledInput";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {useSearchParams, Link, useNavigate} from "react-router-dom";
import {GSPSSuccessMessage} from "Components/Common/GSPSSuccessMessage.styles";
import {AutheniticationBanner} from "Pages/Login/AuthenticationBanner";
import PropTypes from "prop-types";

export const FAILED_SUBMISSION_TEXT = `Error resetting password. Check the link provided, confirm 
you have requested a password reset, then try again`;
export const INCORRECTLY_FORMATED_LINK_TEXT = `Reset Password link used is incorrectly formated. 
Please check the link provided and try again.`;
export const SUCCESS_TEXT = <div><strong>Password Successfully reset. </strong>
Please login <Link to="/" className="link-primary">here</Link> with your new password</div>;

export const ResetPassword = ({resetCurrentUserSession}) => {
    const {handleSubmit, register, formState: {isDirty, touched, errors}, getValues} = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [wasSubmissionFailed, setWasSubmissionFailed] = useState(false);
    const [wasPasswordReset, setWasPasswordReset] = useState(false);
    const [submitionErrors, setSubmittionErrors] = useState(undefined);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowPasswordConfirm, setIsShowPasswordConfirm] = useState(false);
    const [searchParams] = useSearchParams();
    const userId = searchParams.get("user_id");
    const timeStamp = searchParams.get("timestamp");
    const signature = searchParams.get("signature");
    const isMalFormatedLink = [userId, timeStamp, signature].some((element) => element === null);
    const navigate = useNavigate();

    const onSubmit = (values) => {
        setIsSubmitting(true);
        setSubmittionErrors(undefined);
        values["signature"] = signature;
        values["user_id"] = userId;
        values["timestamp"] = timeStamp;
        makeUnauthenticatedPostRequest(API_USERS.resetPassword, values)
            .then(() => {
                setWasPasswordReset(true);
                resetCurrentUserSession(navigate);
            }).catch((e) => {
                setWasSubmissionFailed(true);
                const errorsCombined = formsErrorsDisplayString(e);
                setSubmittionErrors(errorsCombined);
            },
            ).finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div style={{width: "600px", borderRadius: "40px", backgroundColor: "white"}}>
                <AutheniticationBanner header={"Set Password"} text={""}></AutheniticationBanner>

                <FormContainer>
                    <CustomDivWidth67>
                        {isMalFormatedLink &&
                    <GSPSErrorMessage
                        messageText={INCORRECTLY_FORMATED_LINK_TEXT}
                    />}

                        {!isMalFormatedLink && wasPasswordReset &&
                    <GSPSSuccessMessage>{SUCCESS_TEXT}
                    </GSPSSuccessMessage>
                        }

                        {!isMalFormatedLink && !wasPasswordReset &&
                <LoginForm onSubmit={handleSubmit(onSubmit)} noValidate>
                    <LoginFormGroup as={Row}>
                        <p className="text-green-500 text-sm">Password must be at least 8 characters long
                            and include both letters and numbers</p>
                        <GSPSLabelledInput
                            id="password"
                            controlType={isShowPassword ? "text" : "password"}
                            labelName={"New Password"}
                            errors={errors}
                            isRequired={true}
                            register={register}
                            isPassword={true}
                            isShowPassword={isShowPassword}
                            setIsShowPassword={setIsShowPassword}
                            patternType={PatternType.PASSWORD}
                        />
                    </LoginFormGroup>
                    <LoginFormGroup as={Row}>
                        <GSPSLabelledInput
                            id="confirmpassword"
                            controlType={isShowPasswordConfirm ? "text" : "password"}
                            labelName={"Confirm Password"}
                            errors={errors}
                            register={register}
                            isRequired={true}
                            isPassword={true}
                            isShowPassword={isShowPasswordConfirm}
                            setIsShowPassword={setIsShowPasswordConfirm}
                            validate={(value) => {
                                return (value === getValues("password") || "Passwords do not match");
                            }}
                        />
                    </LoginFormGroup>
                    {wasSubmissionFailed &&
                            <GSPSErrorMessage
                                messageText={
                                    submitionErrors ? submitionErrors : FAILED_SUBMISSION_TEXT} />}
                    <LoginFormGroup as={Row}>
                        <GSPSSubmitButton
                            isDisabled={(!isDirty && touched) || isSubmitting}
                            isLoading = {isSubmitting}
                            controlSize={6}
                            offset={6}
                            buttonText={"Set New Password"}>
                        </GSPSSubmitButton>
                    </LoginFormGroup>
                </LoginForm>}
                    </CustomDivWidth67>
                </FormContainer>
            </div>
        </div>
    );
};

ResetPassword.propTypes = {
    resetCurrentUserSession: PropTypes.func,
};

// Flattens form errors object then joins in a single
// string for display
function formsErrorsDisplayString(object) {
    let res = "";
    Object.keys(object).forEach((key) => {
        res += object[key].join(" ");
    });
    return res;
}
