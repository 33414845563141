import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSStyledRow} from "Components/Common/GSPSStyledContainers.styles";
import {Col, Container} from "react-bootstrap";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";
import {AddressForm} from "Components/Common/AddressForm";
import {GSPSFormImageInput} from "Components/Common/GSPSFormImageInput";
import {fetchSelectionOptions} from "Services/ServicesUtils";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {AccountAuditTypes} from "Pages/Accounts/AccountAuditTypes";
import {API_USERS} from "Services/Endpoints";
import {GSPSEnableDisableSwitch} from "Components/Common/GSPSEnableDisableSwitch";

export const AccountForm = ({
    account,
    register,
    errors,
    setImageValue,
    setAuditTypes,
    auditTypes,
    setValue,
    setHasLogo,
}) => {
    const [accountTypes, setAccountTypes] = useState();
    const [users, setUsers] = useState();

    useEffect(() => {
        fetchSelectionOptions(API_ACCOUNTS.accountTypes, setAccountTypes, "name");
        fetchSelectionOptions(API_USERS.usersDropdown, setUsers, "full_name");
    }, []);

    useEffect(() => {
        if (users && account) {
            const defaultAdminId = account?.account_admins[0]?.id;
            const defaultAccountTypeId = account?.account_type?.id;
            setValue("account_admins_ids", defaultAdminId);
            setValue("account_type_id", defaultAccountTypeId);
            setValue("phone", account.phone ?? "");
            setValue("mobile_phone", account.mobile_phone ?? "");
            setHasLogo(account.logo !== null);
        }
    }, [users, account, setValue]);

    return (
        <>
            <Container className="p-3">
                <GSPSStyledRow>
                    <Col xs={10} md={5}>
                        <GSPSLabelledInput
                            id="name"
                            controlType={"input"}
                            value={account && account.name}
                            labelName={"Account Name"}
                            errors={errors}
                            register={register}
                            isRequired={true} />
                        {accountTypes &&
                            <GSPSLabelledSelectField
                                inputID={"account_type_id"}
                                labelName={"Account Type"}
                                defaultValue={account?.account_type.id}
                                selectableOptions={accountTypes}
                                register={register}
                                errors={errors}
                                isRequired={true}
                            />
                        }
                        <AddressForm
                            id="address"
                            caller={account}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                        />
                        <GSPSLabelledInput
                            id="website"
                            value={account && account.website}
                            controlType={"input"}
                            labelName={"Website"}
                            errors={errors}
                            register={register} />

                        {account &&
                                <GSPSEnableDisableSwitch
                                    inputId={"is_active"}
                                    defaultValue={account?.is_active}
                                    register={register}
                                />
                        }
                        <AccountAuditTypes
                            id={"account_audit_types"}
                            account={account}
                            setAuditTypes={setAuditTypes}
                            auditTypes={auditTypes}
                        />
                    </Col>

                    <Col xs={10} md={5}>
                        <GSPSLabelledInput
                            id="external_account_contact"
                            controlType={"input"}
                            labelName={"Account Contact"}
                            errors={errors}
                            register={register}
                        />
                        <GSPSLabelledInput
                            id="external_account_email"
                            controlType={"input"}
                            labelName={"Email"}
                            errors={errors}
                            register={register} />
                        <GSPSLabelledInput
                            id="phone"
                            value={account && account.phone}
                            controlType={"input"}
                            labelName={"Phone"}
                            errors={errors}
                            register={register}
                            isRequired={true}
                            isPhone={true} />
                        <GSPSLabelledInput
                            id="mobile_phone"
                            value={account && account.mobile_phone}
                            controlType={"input"}
                            labelName={"Mobile Phone"}
                            errors={errors}
                            register={register}
                            isPhone={true} />
                        {users &&
                            <GSPSLabelledSelectField
                                inputID={"account_admins_ids"}
                                labelName={"Account Manager"}
                                defaultValue={account && account?.account_admins[0]?.id}
                                selectableOptions={users}
                                register={register}
                                errors={errors}
                                isRequired={true}
                            />
                        }
                        <GSPSFormImageInput
                            id={"logo"}
                            labelName={"Add Logo"}
                            labelSize={5}
                            controlSize={5}
                            imageContainerWidthPx={290}
                            imageContainerHeightPx={175}
                            setValue={setImageValue}
                            currentImageSrc={account && account.logo}
                            handleDeleteImage={() => {
                                setHasLogo(false);
                            }}
                            setHasLogo={setHasLogo}
                            tooltipMessage={"Image must be PNG format"}
                        />
                    </Col>
                </GSPSStyledRow>
            </Container>
        </>
    );
};

AccountForm.propTypes = {
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    account: PropTypes.object,
    setImageValue: PropTypes.func,
    setAuditTypes: PropTypes.func,
    auditTypes: PropTypes.array,
    setValue: PropTypes.func,
    setHasLogo: PropTypes.func,
};
