import {StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 11,
    },
    nav: {
        borderBottom: "3px solid gray",
        paddingBottom: "5px",
        marginBottom: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    navImg: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    },
    navImageContainer: {
        width: "100px",
        height: "60px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        overflow: "hidden",
    },
    section: {
        marginBottom: 10,
        marginTop: 10,
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    flex: {
        display: "flex",
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
    },
    text: {
        fontSize: 11,
        marginBottom: 5,
        paddingBottom: 5,
    },
    headline: {
        fontSize: 14,
        marginBottom: 10,
        fontWeight: 700,
    },
    table: {
        border: "2px solid #dbe6e2",
        borderRadius: "10px",
        padding: "10px",
        marginBottom: "10px",
    },
    titleWithBorder: {
        borderBottom: "2px solid #dbe6e2",
        marginVertical: 5,
        paddingBottom: 5,
    },
    imageContainer: {
        width: "100%",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },
    tableImg: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        marginHorizontal: 20,
    },
    issueImagesContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        marginTop: 5,
    },
    noImagesText: {
        paddingVertical: 10,
    },
    navFullWidth: {
        width: "100%",
    },
    navPartialWidth: {
        width: "75%",
    },
});
export default styles;
