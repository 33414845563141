import {Button} from "react-bootstrap";
import styled from "styled-components";

export const SVPDeleteButtonStyle = styled(Button)`
    border-radius: 0.625rem;
    border-color: ${({theme}) => theme.color.danger};
    background: ${({theme}) => theme.color.danger};
    display: inline-flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    color: ${({theme}) => theme.color.dark};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;

    &:hover {
        background: ${({theme}) => theme.color.switchDisabled};
        border-color: ${({theme}) => theme.color.border};
    }
`;
