import GetAppIcon from "@mui/icons-material/GetApp";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import {Divider} from "@mui/material";

const SVPExportButton = ({
    isExportNotes,
    setIsExportNotes,
    options = [],
    isLoading = false,
    isPublicAudit = false,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const menuOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        {!isLoading ?
            <Button
                variant="outlined"
                className="cta-btn float-end mb-3 z-2"
                id="exportBtn"
                aria-controls={menuOpen ? "menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                onClick={handleClick}
                endIcon={<GetAppIcon />}
                disabled={isLoading}
            >
                Export
            </Button> :
            <Button
                variant="outlined"
                className="cta-btn float-end mb-3 z-2"
                id="loadingBtn"
                disabled={true}
            >
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Loading...
            </Button>
        }
        <Menu
            id="menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "exportBtn",
                "style": {textAlign: "center"},
            }}
            slotProps={{
                paper: {
                    sx: {width: 200},
                },
            }}
        >
            {
                options.map((option, index) => {
                    return <MenuItem
                        key={index}
                        onClick={() => {
                            option.onClick();
                            handleClose();
                        }}
                        sx={{justifyContent: "left", textAlign: "center", ml: 2}}
                    >
                        {option.text}
                    </MenuItem>;
                })
            }
            {!isPublicAudit &&
                <div>
                    <Divider className="my-1" sx={{borderTop: "1px solid"}} />
                    <div className="form-check m-0 d-flex justify-content-center align-items-center">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="select-all"
                            checked={isExportNotes}
                            onChange={(e) => setIsExportNotes(e.target.checked)}
                        />
                        <label className="form-check-label ms-2" htmlFor="select-all">
                            Export with Notes
                        </label>
                    </div>
                </div>
            }
        </Menu>
    </>;
};

SVPExportButton.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    isLoading: PropTypes.bool,
    isExportNotes: PropTypes.bool,
    setIsExportNotes: PropTypes.func,
    isPublicAudit: PropTypes.bool,
};

export default SVPExportButton;
