import React, {useCallback, useEffect, useState} from "react";
import {GoogleMap, Marker, OverlayView, Polygon, Polyline} from "@react-google-maps/api";
import {insertNotification} from "Utils/NotificationsUtils";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationCrosshairs} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {AnnotationShapeIDMap, AnnotationType, AuditStatusID} from "Models/Audit/AuditConstants";
import {
    cancelButtonStyle,
    containerStyle,
    currentLocationButtonStyle,
    currentAreaAndDistanceStyle,
    getAreaAnnotationStyle,
    getItemStyle,
    getLineAnnotationStyle,
    hoverItemStyle,
    undoButtonStyle,
    saveButtonStyle,
    locationLabelStyle,
    locationButtonStyle,
    locationInputStyle,
    locationDivStyle,
} from "./GSPSGoogleMap.styles";
import "./GSPSGoogleMap.css";
import {FaUndo, FaSave} from "react-icons/fa";
import {MdAutoDelete} from "react-icons/md";
import {DrawingMode, SVPDrawingManager} from "Components/Map/SVPDrawingManager";
import {getPathAreaInFt, getPathLengthInFt, getPointLatLngFromEvent} from "Components/Map/SVPGoogleMapUtils";
import {isFeatureSwitchActive} from "Utils/WaffleJSHelper";

export const GSPSGoogleMap = ({
    audit,
    currentLocation,
    annotationAreas,
    annotationLines,
    annotationMarkers,
    onLineClick,
    onIssueClick,
    onAreaClick,
    saveIssue = null,
    saveLine = null,
    saveArea = null,
    setMapClicked = null,
    currentAuditType = null,
    currentAnnotationShape = null,
    handleDropDownResetDone = null,
    currentAuditTypeItem = null,
    issuesSeveritiesWithColor = null,
    isViewOnly = false,
    auditsFilter = {"items": [], "lines": [], "areas": [], "default": true},
}) => {
    const [currentDrawingMode, setCurrentDrawingMode] = useState(null);
    const [map, setMap] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [isCancelDrawing, setIsCancelDrawing] = useState(false);
    const [linePoints, setLinePoints] = useState([]);
    const [currentLineLength, setCurrentLineLength] = useState(null);
    const [areaPoints, setAreaPoints] = useState([]);
    const [calculatedAreaInFt, setCalculatedAreaInFt] = useState(null);
    const [activePolygon, setActivePolygon] = useState(null);
    const [isUndoPress, setIsUndoPress] = useState(false);
    const [currentClickedLocation, setCurrentClickedLocation] = useState(currentLocation);
    const [isShowLocationMarker, setIsShowLocationMarker] = useState(false);

    const resetArea = () => {
        setAreaPoints([]);
        setCalculatedAreaInFt(null);
        setActivePolygon(null);
    };

    const resetAreaAndKeepDrawing = () => {
        resetArea();
        setCurrentDrawingMode(DrawingMode.POLYGON);
    };

    const resetLine = () => {
        setLinePoints([]);
        setCurrentLineLength(null);
    };

    const handleUndo = () => {
        if (currentDrawingMode === DrawingMode.POLYLINE) {
            return;
        }

        setIsUndoPress(true);
        setCurrentDrawingMode(null);

        if (activePolygon) {
            const path = activePolygon.getPath();
            if (path.getLength() > 0) {
                path.pop(); // Remove the last point (Undo)
                setCalculatedAreaInFt(getPathAreaInFt(path).toFixed(2));
            }
            if (path.getLength() === 0) {
                activePolygon.setMap(null); // Remove polygon if empty
                setIsUndoPress(false);
                resetAreaAndKeepDrawing();
                return;
            }
            activePolygon.setEditable(true);

            google.maps.event.clearListeners(activePolygon, "click");
            google.maps.event.addListener(activePolygon, "click", (event) => {
                const newPath = activePolygon.getPath();
                newPath.push(event.latLng);
            });
            setCurrentDrawingMode(true);
            setMapClicked(false);
            setIsUndoPress(false);
        }
    };


    const getLatLngFromEvent = useCallback((event) => {
        if (!map) return null;
        return getPointLatLngFromEvent(event, map);
    }, [map]);

    const handlePolylineMove = useCallback((event) => {
        if (linePoints.length < 1) {
            return;
        }

        const latLng = getLatLngFromEvent(event);
        if (latLng) {
            const path = [...linePoints];
            path.push({lat: latLng.lat(), lng: latLng.lng()});
            setCurrentLineLength(getPathLengthInFt(path).toFixed(2));
        }
    }, [linePoints, getLatLngFromEvent]);

    const handleAddLinePoint = useCallback((event) => {
        const latLng = getLatLngFromEvent(event);
        if (latLng) {
            setLinePoints((prevPoints) => prevPoints.concat({lat: latLng.lat(), lng: latLng.lng()}));
        }
    }, [getLatLngFromEvent]);

    useEffect(() => {
        if (map && currentDrawingMode === DrawingMode.POLYLINE) {
            const startMouseListener = google.maps.event.addDomListener(
                map.getDiv(),
                "mousedown",
                handleAddLinePoint,
            );
            const moveMouseListener = google.maps.event.addDomListener(
                map.getDiv(),
                "mousemove",
                handlePolylineMove,
            );

            return () => {
                google.maps.event.removeListener(startMouseListener);
                google.maps.event.removeListener(moveMouseListener);
            };
        }
    }, [map, currentDrawingMode, handleAddLinePoint, handlePolylineMove]);

    const handleAddAreaPoint = useCallback((event) => {
        const latLng = getLatLngFromEvent(event);
        if (latLng) {
            setAreaPoints((prevPoints) => prevPoints.concat({lat: latLng.lat(), lng: latLng.lng()}));
        }
    }, [getLatLngFromEvent]);

    const handleAreaChange = useCallback((event) => {
        if (areaPoints.length < 2) {
            return;
        }

        const latLng = getLatLngFromEvent(event);
        if (latLng) {
            const path = [...areaPoints];

            path.push({lat: latLng.lat(), lng: latLng.lng()});
            setCalculatedAreaInFt(getPathAreaInFt(path).toFixed(2));
        }
    }, [areaPoints, getLatLngFromEvent]);

    useEffect(() => {
        if (map && currentDrawingMode === DrawingMode.POLYGON) {
            const startMouseListener = google.maps.event.addDomListener(
                map.getDiv(),
                "mousedown",
                handleAddAreaPoint,
            );
            const moveMouseListener = google.maps.event.addDomListener(
                map.getDiv(),
                "mousemove",
                handleAreaChange,
            );

            return () => {
                google.maps.event.removeListener(startMouseListener);
                google.maps.event.removeListener(moveMouseListener);
            };
        }
    }, [map, currentDrawingMode, handleAddAreaPoint, handleAreaChange]);

    useEffect(() => {
        if (map) {
            map.addListener("zoom_changed", handleZoomChange);
        }
        return () => {
            if (map) {
                google.maps.event.clearListeners(map, "zoom_changed");
            }
        };
    }, [map]);

    useEffect(() => {
        const isAnnotationOptionSelected = currentAuditType &&
            currentAuditTypeItem;
        const isAnnotationTypeSelected = currentAnnotationShape?.query === AnnotationType.AREA ||
            currentAnnotationShape?.query === AnnotationType.LINE;

        setCurrentDrawingMode(
            isAnnotationTypeSelected && isAnnotationOptionSelected ?
                currentAnnotationShape?.query === AnnotationType.AREA ?
                    DrawingMode.POLYGON :
                    DrawingMode.POLYLINE :
                null,
        );
        resetLine();
        resetArea();
    }, [currentAnnotationShape, currentAuditType, currentAuditTypeItem]);

    const onMapClick = useCallback(async (event) => {
        if (!currentAuditType || !currentAnnotationShape || !currentAuditTypeItem) {
            !isCancelDrawing && insertNotification(
                "Error", "Please select Audit Type, Item Type and Item first", "error",
            );
            setIsCancelDrawing(false);
            return;
        }
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        if (currentAnnotationShape?.query === AnnotationShapeIDMap.ISSUE.query) {
            await saveIssue({lat, lng});
            handleDropDownResetDone();
            setMapClicked(true);
        }
    }, [currentAnnotationShape, saveIssue, handleDropDownResetDone,
        setMapClicked, currentAuditType, currentAuditTypeItem, isCancelDrawing,
    ]);

    const handleZoomChange = () => {
        setHoveredItem(null);
    };

    const handleCancelDrawing = () => {
        setIsCancelDrawing(true);

        if (activePolygon) {
            activePolygon.setMap(null);
        }
        resetArea();
        resetLine();
        setCurrentDrawingMode(null);
    };

    const createCustomIcon = (marker) => ({
        path: "M10,20a10,10 0 1,1 0,-20a10,10 0 1,1 0,20",
        fillColor: marker.audit_type_item.color,
        fillOpacity: 1,
        strokeColor: getBorderColor(marker),
        strokeWeight: 3,
        scale: 1.5,
        labelOrigin: new google.maps.Point(10, 10),
    });

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);

    const handlePolygonComplete = async (polygon) => {
        setActivePolygon(polygon);

        if (!isUndoPress) {
            await onPolygonComplete(polygon);
        }
    };

    const onSaveShape = async () => {
        if (activePolygon) {
            await onPolygonComplete(activePolygon);
            setActivePolygon(null);
        } else {
            setCurrentDrawingMode(null);
        }
    };

    const onPolygonComplete = async (shape) => {
        if (!currentAuditType || !currentAnnotationShape || !currentAuditTypeItem) {
            !isCancelDrawing && insertNotification(
                "Error", "Please select Audit Type, Item Type and Item first", "error",
            );
            shape.setMap(null);
            setIsCancelDrawing(false);
            return;
        }

        if (isCancelDrawing) {
            shape.setMap(null);
            setIsCancelDrawing(false);
            resetAreaAndKeepDrawing();
            return;
        }

        const path = shape.getPath().getArray().map((point) => ({lat: point.lat(), lng: point.lng()}));
        if (shape.type === DrawingMode.POLYGON) {
            if (path.length <= 2) {
                insertNotification("Error", "An area must have more than two points.", "error");
                shape.setMap(null);
                resetAreaAndKeepDrawing();
                return;
            }
            const areaInSquareFeet = getPathAreaInFt(path);
            await saveArea({path, areaInSquareFeet});

            shape.setMap(null);
            setCurrentDrawingMode(null);
            handleDropDownResetDone();
            setMapClicked(true);
            resetArea();
        }
    };

    const getLabelPosition = (path) => {
        let latSum = 0;
        let lngSum = 0;
        path.forEach((point) => {
            latSum += point.lat;
            lngSum += point.lng;
        });
        const lat = latSum / path.length;
        const lng = lngSum / path.length;
        return {lat, lng};
    };

    const goToCurrentLocation = useCallback(() => {
        if (map) {
            const successCallback = (position) => {
                const {latitude, longitude} = position.coords;
                const newCenter = {lat: latitude, lng: longitude};
                map.panTo(newCenter);
                setCurrentClickedLocation(newCenter);
                setIsShowLocationMarker(true);
            };

            const errorCallback = () => {
                insertNotification("Error",
                    "Unable to detect your location. Enable location permission for the browser.",
                    "error");
            };
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
                maximumAge: 0, timeout: 5000, enableHighAccuracy: true,
            });
        }
        // eslint-disable-next-line
    }, [map]);

    const getZoomLevel = () => {
        if (audit.audit_status.id !== AuditStatusID.IN_PROGRESS &&
            (annotationAreas.length > 0 || annotationLines.length > 0 || annotationMarkers.length > 0)
        ) {
            return 18;
        } else {
            return 20;
        }
    };

    const onPolylineComplete = async (polyline) => {
        if (!currentAuditType || !currentAnnotationShape || !currentAuditTypeItem) {
            !isCancelDrawing && insertNotification(
                "Error", "Please select Audit Type, Item Type and Item first", "error",
            );
            polyline.setMap(null);
            setIsCancelDrawing(false);
            return;
        }

        if (isCancelDrawing) {
            polyline.setMap(null);
            setCurrentDrawingMode(DrawingMode.POLYLINE);
            setIsCancelDrawing(false);
            setActivePolygon(null);
            resetLine();
            return;
        }
        resetLine();

        const path = polyline.getPath().getArray().map((point) => ({lat: point.lat(), lng: point.lng()}));
        const pathLength = path.length;

        if (pathLength > 1) {
            await saveLine(path, getPathLengthInFt(path));

            polyline.setMap(null);
            setCurrentDrawingMode(null);
            handleDropDownResetDone();
            setMapClicked(true);
            return;
        } else if (pathLength === 1 || pathLength === 0) {
            insertNotification("Error", "A Line must have more than one point.", "error");
            polyline.setMap(null);
        } else {
            insertNotification("Error", "can't read lineٍ.", "error");
            polyline.setMap(null);
            setLinePoints([]);
        }
        setCurrentDrawingMode(DrawingMode.POLYLINE);
    };

    function getBorderColor(annotation) {
        return isViewOnly ?
            annotation.severity_object?.color_code :
            issuesSeveritiesWithColor?.find((issueSeverity) =>
                issueSeverity.id === Number(annotation.severity))?.color_code;
    }

    function getItemBackgroundColor() {
        return issuesSeveritiesWithColor?.find(
            (severity) => severity.id === Number(hoveredItem.severity),
        )?.color_code;
    }

    const onItemMouseOver = (item) => {
        setHoveredItem(item);
    };
    const onItemMouseOut = () => {
        setHoveredItem(null);
    };

    function renderIssues() {
        return annotationMarkers.map((marker, index) => {
            return (((auditsFilter.items?.includes(marker.audit_type_item.name?.trim()) &&
                    auditsFilter.severity?.includes(marker.severity_object.name?.trim())) ||
                auditsFilter.default) && <Marker
                key={index}
                position={{lat: Number(marker.address_lnglat.lat), lng: Number(marker.address_lnglat.lng)}}
                draggable={false}
                onClick={() => onIssueClick(marker)}
                onMouseOver={() => onItemMouseOver({
                    ...marker,
                    position: {
                        lat: Number(marker.address_lnglat.lat), lng: Number(marker.address_lnglat.lng),
                    },
                })}
                icon={createCustomIcon(marker)}
                label={{
                    text: String(index + 1),
                    color: "black",
                    fontWeight: "light",
                    fontSize: "18px",
                }}
            />);
        });
    }

    function renderHoverInfo() {
        return <OverlayView
            position={hoveredItem?.position ? hoveredItem.position : {
                lat: Number(hoveredItem.address_lnglat.lat),
                lng: Number(hoveredItem.address_lnglat.lng),
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
            <div
                style={hoverItemStyle}
            >
                <div>
                    <strong>{hoveredItem.audit_type_item.name}</strong> <br />
                    {!hoveredItem?.position && (
                        <div style={{display: "flex", alignItems: "center"}}>
                            <div
                                style={getItemStyle(getItemBackgroundColor())}
                            />
                            {
                                issuesSeveritiesWithColor?.find(
                                    (severity) => severity.id === Number(hoveredItem.severity),
                                )?.name
                            }
                        </div>
                    )}
                </div>
            </div>
        </OverlayView>;
    }

    function renderAreas() {
        return annotationAreas.map((area, index) => {
            const path = area.vertices_address_lnglat.map((path) =>
                ({lat: Number(path.lat), lng: Number(path.lng)})) ?? {};

            return (
                (auditsFilter.areas.includes(area.audit_type_item.name?.trim()) || auditsFilter.default) &&
                <React.Fragment
                    key={area.index}
                >
                    <Polygon
                        paths={path}
                        options={{
                            fillColor: `${area.audit_type_item.color ?? "blue"}`,
                            fillOpacity: 0.7,
                            strokeColor: "blue",
                            strokeOpacity: 1,
                            strokeWeight: 0,
                        }}
                    />
                    <OverlayView
                        position={getLabelPosition(path)}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                onAreaClick(area);
                            }}
                            onMouseOver={() => onItemMouseOver({...area, position: getLabelPosition(path)})}
                            onMouseOut={onItemMouseOut}
                            style={getAreaAnnotationStyle(getBorderColor(area))}
                        >
                            {index + 1}
                        </div>
                    </OverlayView>
                </React.Fragment>
            );
        });
    }

    function renderLines() {
        return annotationLines.map((line, index) => {
            let path = line.vertices_address_lnglat_ordered.map((path) =>
                ({lat: Number(path.lat), lng: Number(path.lng)})) ?? {};

            // backward compatibility with old structure
            if (path.length === 0) {
                const {address_lnglat1: addressLngLat1, address_lnglat2: addressLngLat2} = line;
                path = [
                    {lat: Number(addressLngLat1.lat), lng: Number(addressLngLat1.lng)},
                    {lat: Number(addressLngLat2.lat), lng: Number(addressLngLat2.lng)},
                ];
            }

            return (
                (auditsFilter.lines.includes(line.audit_type_item.name?.trim()) || auditsFilter.default) &&
                <React.Fragment
                    key={index}
                >
                    <Polyline
                        onClick={() => {
                        }}
                        path={path}
                        value={line}
                        options={{
                            strokeColor: `${line.audit_type_item.color ?? "red"}`,
                            strokeOpacity: 1,
                            strokeWeight: 3,
                        }}
                    />
                    <OverlayView
                        position={getLabelPosition(path)}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                onLineClick(line);
                            }}
                            onMouseOver={() => onItemMouseOver({...line, position: getLabelPosition(path)})}
                            onMouseOut={onItemMouseOut}
                            style={getLineAnnotationStyle(getBorderColor(line))}
                        >
                            {index + 1}
                        </div>
                    </OverlayView>
                </React.Fragment>
            );
        });
    }

    return (
        <div style={containerStyle} id="map-container">
            <Button
                onClick={goToCurrentLocation}
                style={currentLocationButtonStyle}
            >
                <FontAwesomeIcon icon={faLocationCrosshairs} />
            </Button>
            {currentLineLength && (
                <div style={currentAreaAndDistanceStyle}>
                    Distance: {currentLineLength} ft
                </div>
            )}
            {calculatedAreaInFt && (
                <div style={currentAreaAndDistanceStyle}>
                    Area: {calculatedAreaInFt} ft²
                </div>
            )}
            <GoogleMap
                id={"google-map"}
                mapContainerStyle={containerStyle}
                center={currentLocation}
                zoom={getZoomLevel()}
                onLoad={onLoad}
                onUnmount={() => setMap(null)}
                options={{
                    streetViewControl: true,
                    mapTypeId: "satellite",
                    fullscreenControl: true,
                    zoomControl: true,
                    controlSize: 24,
                    fullscreenControlOptions: {position: google.maps.ControlPosition.BOTTOM_RIGHT},
                    zoomControlOptions: true,
                    rotateControl: true,
                    rotateControlOptions: {position: google.maps.ControlPosition.BOTTOM_LEFT},
                    draggableCursor: "crosshair",
                    tilt: 0,
                    gestureHandling: isFeatureSwitchActive("is_map_greedy") ? "greedy" : "auto",
                }}
                onClick={!isViewOnly && onMapClick}
            >
                {currentClickedLocation && isShowLocationMarker && (
                    <Marker
                        position={currentClickedLocation}
                        icon={{
                            url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Custom marker icon
                            scaledSize: new window.google.maps.Size(40, 40), // Resize the marker
                        }}
                    />
                )}
                {annotationMarkers.length > 0 && auditsFilter.items && renderIssues()}
                {hoveredItem && renderHoverInfo()}
                {annotationAreas.length > 0 && auditsFilter.areas && renderAreas()}
                {annotationLines.length > 0 && auditsFilter.lines && renderLines()}
                <SVPDrawingManager
                    drawingMode={currentDrawingMode}
                    onPolylineComplete={onPolylineComplete}
                    onPolygonComplete={handlePolygonComplete}
                />
            </GoogleMap>
            <div style={locationDivStyle}>
                <button style={locationButtonStyle}>
                    <label style={locationLabelStyle}>
                        <input
                            type="checkbox"
                            checked={isShowLocationMarker}
                            onChange={() => setIsShowLocationMarker(!isShowLocationMarker)}
                            style={locationInputStyle}
                        />
                        Location
                    </label>
                </button>
            </div>
            <button onClick={handleCancelDrawing} style={cancelButtonStyle}>
                <MdAutoDelete /> Cancel
            </button>
            {isFeatureSwitchActive("is_map_undo_enabled") &&
                <button onClick={handleUndo} style={undoButtonStyle}>
                    <FaUndo /> Undo
                </button>
            }
            <button onClick={onSaveShape} style={saveButtonStyle}>
                <FaSave /> Save
            </button>
        </div>
    );
};

GSPSGoogleMap.propTypes = {
    audit: PropTypes.object.isRequired,
    annotationAreas: PropTypes.array.isRequired,
    annotationLines: PropTypes.array.isRequired,
    annotationMarkers: PropTypes.array.isRequired,
    onIssueClick: PropTypes.func.isRequired,
    onLineClick: PropTypes.func.isRequired,
    onAreaClick: PropTypes.func.isRequired,
    currentLocation: PropTypes.object,
    saveIssue: PropTypes.func,
    saveLine: PropTypes.func,
    saveArea: PropTypes.func,
    setMapClicked: PropTypes.func,
    currentAnnotationShape: PropTypes.object,
    currentAuditType: PropTypes.object,
    auditsFilter: PropTypes.object,
    handleDropDownResetDone: PropTypes.func,
    currentAuditTypeItem: PropTypes.object,
    isViewOnly: PropTypes.bool,
    issuesSeveritiesWithColor: PropTypes.array,
};
