import React, {useState} from "react";
import PropTypes from "prop-types";
import {Box, IconButton, Modal, Tooltip} from "@mui/material";
import {insertNotification} from "Utils/NotificationsUtils";
import ConfirmationModal from "Components/Common/ConfirmationModal";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useTheme} from "styled-components";
import {useIsDesktop} from "Services/ServicesUtils";
import GSPSPropDrivenTable from "Components/DataTable/GSPSPropDrivenTable";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {AnnotationType} from "Models/Audit/AuditConstants";
import {convertToEst} from "Services/TimeZonesUtils";
import {
    imageTooltipPopperStyle,
    imageTooltipTitleImageStyle,
    imageTooltipTitleStyle,
} from "Pages/Audits/AuditTable.styles";
import {SVPToolTipIcon} from "Components/ToolTip/SVPToolTipIcon";


export const AuditTable = ({
    items,
    headerName,
    elementType,
    dimensionHeader,
    onDelete,
    onEdit,
    isViewOnly = true,
    isPublicAudit = false,
}) => {
    const [modalShow, setModalShow] = useState(false);
    const [videoModalShow, setVideoModalShow] = useState(null);
    const [idToDelete, setIdToDelete] = useState(null);
    const [openTooltipIndex, setOpenTooltipIndex] = useState(null);
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const handleDelete = async () => {
        try {
            await onDelete(idToDelete);
            setModalShow(false);
            setIdToDelete(null);
            if (elementType === AnnotationType.AREA) {
                insertNotification("Success", "Area has been deleted.", "Success");
            }
        } catch (error) {
            insertNotification("Error", `Can't delete area. Reason: ${error.message}`, "error");
        }
    };

    function renderNotesCol() {
        return {
            field: "notes",
            sortable: false,
            ...(isDesktop ? {flex: 3} : {width: 200}),
            headerClassName: "header",
            renderHeader: () => (
                <span>
                    Notes
                    <SVPToolTipIcon
                        fontSize="20px"
                        placement="top"
                        message="Internal"
                        rightMargin = "5px"
                    />
                </span>
            ),
            renderCell: (params) => params.value ? params.value : "-",
        };
    }

    function renderDimension() {
        return {
            field: `${elementType === "line" ? "length" : elementType}_feet`,
            headerName: dimensionHeader,
            width: 100,
            headerClassName: "header",
            renderCell: (params) => {
                const value = params.value || 0; // default value
                return parseFloat(value).toFixed(2);
            },
        };
    }

    function renderActions() {
        return {
            field: "actions",
            headerName: "Actions",
            width: 100,
            headerAlign: "right",
            headerClassName: "header",
            renderCell: (params) => <div className="actions w-100 d-flex justify-content-end">
                <button className="border-0 bg-transparent px-2" onClick={() => {
                    setIdToDelete(params.row.id);
                    setModalShow(true);
                }}>
                    <Tooltip title="Delete">
                        <DeleteIcon fontSize="small" sx={{color: theme.color.textOnBg}} />
                    </Tooltip>
                </button>
                <button className="border-0 bg-transparent px-0 ps-2" onClick={() => onEdit(params.row)}>
                    <Tooltip title="Edit">
                        <EditIcon fontSize="small" sx={{color: theme.color.textOnBg}} />
                    </Tooltip>
                </button>
            </div>,
        };
    }

    function renderImages(images, params) {
        if (images && images.length > 0) {
            return (
                <div>
                    {images.map((image, index) =>
                        image.image_value &&
                        <Tooltip
                            key={`${params.row.id}-${index}`}
                            title={
                                <div style={imageTooltipTitleStyle}>
                                    <img
                                        src={image.image_value}
                                        className="img-fluid"
                                        alt="full size"
                                        style={imageTooltipTitleImageStyle}
                                    />
                                </div>
                            }
                            PopperProps={imageTooltipPopperStyle}
                            disableFocusListener={true}
                            disableHoverListener={true}
                            disableTouchListener={true}
                            open={openTooltipIndex === `${params.row.id}-${index}`}
                            onClose={() => setOpenTooltipIndex(null)}
                        >
                            <button
                                className="imgContainer border-0 bg-transparent mx-3 my-2 p-0"
                                style={{width: "120px"}}
                                onClick={() => setOpenTooltipIndex(`${params.row.id}-${index}`)}
                                onMouseLeave={() => setOpenTooltipIndex(null)}
                            >
                                <img
                                    src={image.image_value}
                                    className="img-fluid rounded-1"
                                    alt="audit thumbnail"
                                />
                                {convertToEst(image.created_at, true)}
                            </button>
                        </Tooltip>)}
                </div>
            );
        } else {
            return "-";
        }
    }

    function renderVideos(videos, rowId, modalStyle) {
        if (videos && videos.length > 0) {
            return (
                <div>
                    {videos.map((video, index) =>
                        video.video_value && (
                            <div key={`${rowId}-${index}`}>
                                <button
                                    className="imgContainer border-0 bg-transparent p-0"
                                    style={{width: "90px"}}
                                    onClick={() => setVideoModalShow(
                                        videoModalShow === video.video_value ? null : video.video_value,
                                    )}
                                >
                                    Play video
                                    <video
                                        src={video.video_value}
                                        className="img-fluid rounded-1"
                                        playsInline={true}
                                        muted={true}
                                        preload="metadata"
                                    >
                                    </video>
                                    {convertToEst(video.created_at, true)}
                                </button>
                                <Modal
                                    open={videoModalShow === video.video_value}
                                    onClose={() => setVideoModalShow(null)}
                                >
                                    <Box sx={modalStyle}>
                                        <IconButton
                                            aria-label="close"
                                            size="large"
                                            onClick={() => setVideoModalShow(null)}
                                            sx={{
                                                position: "absolute",
                                                top: "12px",
                                                right: "15px",
                                                zIndex: "5000",
                                                color: theme.color.bodyBackground,
                                            }}>
                                            <CloseRoundedIcon />
                                        </IconButton>
                                        <video
                                            className="w-100 rounded"
                                            src={video.video_value}
                                            controls={true}
                                            autoPlay={true}
                                        ></video>
                                        {convertToEst(video.created_at)}
                                    </Box>
                                </Modal>
                            </div>
                        ))}
                </div>
            );
        } else {
            return "-";
        }
    }

    const columns = [
        {field: "index", headerName: "ID", width: 30, headerClassName: "header"},
        {
            field: "auditTypeName",
            headerName: "Audit Type",
            ...(isDesktop ? {flex: 2} : {width: 200}),
            headerClassName: "header",
            renderCell: (params) => params.row.audit_type_item.audit_type.name,
        },
        {
            field: "auditTypeItemsName",
            headerName: "Issues",
            ...(isDesktop ? {flex: 2} : {width: 200}),
            headerClassName: "header",
            renderCell: (params) => params.row.audit_type_item.name,
        },
        {
            field: "severity_object",
            headerName: "Severity",
            width: 150,
            headerClassName: "header",
            renderCell: (params) =>
                <span>
                    <CircleIcon fontSize="small" className="me-2" sx={{color: params.value.color_code}} />
                    {params.value.name}
                </span>,
        },
        {
            field: `${elementType}_images`,
            headerName: "Image",
            sortable: false,
            ...(isDesktop ? {flex: 3} : {width: 400}),
            headerClassName: "header",
            renderCell: (params) => {
                const images = params.value;
                return renderImages(images, params);
            },
        },
        {
            field: `${elementType}_videos`,
            headerName: "Video",
            sortable: false,
            ...(isDesktop ? {flex: 2} : {width: 200}),
            headerClassName: "header",
            renderCell: (params) => {
                const videos = params.value;
                const rowId = params.row.id;
                const modalStyle = {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 800,
                    bgcolor: theme.color.bodyBackground,
                    border: `2px solid ${theme.color.border}`,
                    borderRadius: "12px",
                    boxShadow: 24,
                    p: 2,
                };
                return renderVideos(videos, rowId, modalStyle);
            },
        },
        {
            field: "description",
            headerName: "Description",
            sortable: false,
            width: 200,
            headerClassName: "header",
            renderHeader: () => (
                <span>
                    Description
                    <SVPToolTipIcon
                        fontSize="20px"
                        placement="top"
                        message="External"
                        rightMargin = "5px"
                    />
                </span>
            ),
            renderCell: (params) => params.value ? params.value : "-",
        },
        ...(!isPublicAudit ? [renderNotesCol()] : []),
        ...(elementType !== "issue" ? [renderDimension()] : []),
        ...(!isViewOnly ? [renderActions()] : []),
    ];

    return (
        <div className="auditTable my-5">
            <ConfirmationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                message={`Are you sure you want to delete this ${elementType}?`}
                onConfirm={handleDelete}
            />

            <GSPSPropDrivenTable
                title={headerName}
                data={items}
                columns={columns}
                disableColumnResize={true}
                disableColumnMenu={true}
                rowHeight="auto"
            />
        </div>
    );
};


AuditTable.propTypes = {
    headerName: PropTypes.string.isRequired,
    dimensionHeader: PropTypes.string.isRequired,
    items: PropTypes.array,
    updateElementImages: PropTypes.func,
    updateElementVideos: PropTypes.func,
    isViewOnly: PropTypes.bool,
    elementType: PropTypes.string,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    isPublicAudit: PropTypes.bool,
};

