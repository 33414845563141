import React from "react";
import PropTypes from "prop-types";
import {Document, Image, Page, Text, View} from "@react-pdf/renderer";
import * as TimeZonesUtils from "Services/TimeZonesUtils";
import styles from "./AuditReportPDF.styles";

const AuditReportPDF = ({audit, mapImage, canExportNotes}) => {
    const currentDate = new Date();

    function getTitle(index, ant) {
        return `${index + 1} - ${ant.audit_type_item.name} | ${ant.severity_object.name || "N/A"}`;
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <ReportNav audit={audit} />
                {mapImage && (
                    <View style={styles.section}>
                        <Text style={styles.headline}>Map</Text>
                        <Image src={mapImage} />
                    </View>
                )}
            </Page>
            {/* Render Issues */}
            {renderTablesInChunks(
                audit.audit_issues,
                "Issues",
                () => <ReportNav audit={audit} />,
                (issue, index) => (
                    <Table
                        title={getTitle(index, issue)}
                        description={issue.description}
                        notes={canExportNotes ? issue.notes : "" }
                        content={
                            issue.issue_images.length ? (
                                <View style={styles.issueImagesContainer}>
                                    {issue.issue_images.map((image, idx) => (
                                        <View style={styles.imageContainer} key={idx}>
                                            <Image
                                                key={image.image_sequence}
                                                src={image.image_value}
                                                style={styles.tableImg}
                                            />
                                        </View>
                                    ))}
                                </View>
                            ) : (
                                <Text style={styles.noImagesText}>This issue has no images</Text>
                            )
                        }
                    />
                ),
            )}
            {/* Render Lines */}
            {renderTablesInChunks(
                audit.audit_lines,
                "Lines",
                () => <ReportNav audit={audit} />,
                (line, index) => (
                    <Table
                        title={getTitle(index, line)}
                        description={line.description}
                        content={
                            line.line_images.length ? (
                                <View style={styles.issueImagesContainer}>
                                    {line.line_images.map((image, idx) => (
                                        <View style={styles.imageContainer} key={idx}>
                                            <Image
                                                key={image.image_sequence}
                                                src={image.image_value}
                                                style={styles.tableImg}
                                            />
                                        </View>
                                    ))}
                                </View>
                            ) : (
                                <Text style={styles.noImagesText}>This line has no images</Text>
                            )
                        }
                    />
                ),
            )}
            {/* Render Areas */}
            {renderTablesInChunks(
                audit.audit_areas,
                "Areas",
                () => <ReportNav audit={audit} />,
                (area, index) => (
                    <Table
                        title={getTitle(index, area)}
                        description={area.description}
                        content={
                            area.area_images.length ? (
                                <View style={styles.issueImagesContainer}>
                                    {area.area_images.map((image, idx) => (
                                        <View style={styles.imageContainer} key={idx}>
                                            <Image
                                                key={image.image_sequence}
                                                src={image.image_value}
                                                style={styles.tableImg}
                                            />
                                        </View>
                                    ))}
                                </View>
                            ) : (
                                <Text style={styles.noImagesText}>This area has no images</Text>
                            )
                        }
                    />
                ),
            )}
            {/* Additional Information */}
            <Page size="A4" style={styles.page}>
                <ReportNav audit={audit} />
                {canExportNotes &&
                    <View style={styles.table}>
                        <Text style={[styles.titleWithBorder, styles.headline]}>
                            Auditor Additional Information
                        </Text>
                        <View style={{marginVertical: 5}}>
                            <Text>{audit.auditor_additional_notes || "No additional notes"}</Text>
                        </View>
                    </View>
                }
                <View style={styles.table}>
                    <Text style={[styles.titleWithBorder, styles.headline]}>
                        Reviewer Additional Information
                    </Text>
                    <View style={{marginVertical: 5}}>
                        <Text>{audit.reviewer_additional_notes || "No additional notes"}</Text>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <ReportNav audit={audit} />
                <View style={styles.table}>
                    <Text style={[styles.titleWithBorder, styles.headline]}>Reviewer Information</Text>
                    <View style={{marginVertical: 5}}>
                        <Text>Name: {audit?.reviewer?.full_name}</Text>
                        <Text>Phone: {audit?.reviewer?.mobile_phone || "N/A"}</Text>
                        <Text>Email: {audit?.reviewer?.email || "N/A"}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <Text style={[styles.titleWithBorder, styles.headline]}>Location Information</Text>
                    <View style={{marginVertical: 5}}>
                        <Text>Address: {audit.location.address.full_address || "N/A"}</Text>
                        <Text>Contact: {audit.location.account.name || "N/A"}</Text>
                        <Text>Phone: {audit.location.account.phone || "N/A"}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    <Text style={[styles.titleWithBorder, styles.headline]}>Report Summary</Text>
                    <View style={{marginVertical: 5}}>
                        <Text>Report Generated: {TimeZonesUtils.convertToEst(currentDate)}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

const ReportNav = ({audit}) => {
    return (
        <View style={styles.nav}>
            <View>
                {audit.account.logo && (
                    <View style={styles.navImageContainer}>
                        <Image src={audit.account.logo} style={styles.navImg} />
                    </View>
                )}
            </View>
            <View style={audit.account.logo ? styles.navPartialWidth : styles.navFullWidth}>
                <Text style={[styles.headline, styles.flexColumn]}>
                    Report summary: {audit.name.trim()} - {audit.account.name.trim()}
                </Text>
                <Text style={styles.headline}>{audit.location.address.full_address}</Text>
            </View>
        </View>
    );
};

const renderTablesInChunks = (items, title, Nav, renderItem) => {
    const pages = [];
    for (let i = 0; i < items.length; i += 2) {
        const chunk = items.slice(i, i + 2);
        pages.push(
            <Page size="A4" style={styles.page} key={`${title}-${i}`}>
                <Nav />
                <Text style={styles.headline}>{title}</Text>
                <View style={styles.section}>
                    {chunk.map((item, index) => renderItem(item, i + index))}
                </View>
            </Page>,
        );
    }
    return pages;
};

const Table = ({title, id, description, notes, content}) => {
    return (
        <View key={id} style={styles.table}>
            <Text style={[styles.titleWithBorder, styles.headline]}>{title}</Text>
            {description && <Text>{description}</Text>}
            {notes && <Text>Notes: {notes}</Text>}
            <View style={{marginVertical: 5}}>
                {content}
            </View>
        </View>
    );
};

AuditReportPDF.propTypes = {
    audit: PropTypes.shape({
        name: PropTypes.string.isRequired,
        account: PropTypes.shape({
            name: PropTypes.string.isRequired,
            logo: PropTypes.string,
        }).isRequired,
        location: PropTypes.shape({
            address: PropTypes.shape({
                full_address: PropTypes.string,
            }).isRequired,
            account: PropTypes.shape({
                name: PropTypes.string,
                phone: PropTypes.string,
            }).isRequired,
            contact_name: PropTypes.string,
            phone: PropTypes.string,
        }).isRequired,
        reviewer: PropTypes.shape({
            full_name: PropTypes.string,
            mobile_phone: PropTypes.string,
            email: PropTypes.string,
        }).isRequired,
        auditor_additional_notes: PropTypes.string,
        reviewer_additional_notes: PropTypes.string,
        audit_issues: PropTypes.arrayOf(PropTypes.object).isRequired,
        audit_lines: PropTypes.arrayOf(PropTypes.object).isRequired,
        audit_areas: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
    mapImage: PropTypes.string,
    canExportNotes: PropTypes.bool,
};

ReportNav.propTypes = {
    audit: PropTypes.shape({
        name: PropTypes.string.isRequired,
        account: PropTypes.shape({
            name: PropTypes.string.isRequired,
            logo: PropTypes.string,
        }).isRequired,
        location: PropTypes.shape({
            address: PropTypes.shape({
                full_address: PropTypes.string,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

renderTablesInChunks.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string.isRequired,
    Nav: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
};

Table.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string,
    description: PropTypes.string,
    notes: PropTypes.string,
    content: PropTypes.node,
};

export default AuditReportPDF;
