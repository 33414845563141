import styled from "styled-components";

export const ToolbarContainer = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.color.border};
    background: ${({theme}) => theme.color.secondaryBg};

    .title{
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.25px;
    }
    .inputsGroup{
        border: 3px solid ${({theme}) => theme.color.border};
        min-height: 60px;
    }
    @media (min-width: 992px) { /* Large screens (lg and above) */
      .inputsGroup {
        min-height: 71px;
      }
`;
