import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {MediaViewer} from "Pages/Audits/MediaViewer";
import {makeGetRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {AnnotationType} from "Models/Audit/AuditConstants";

export const MediaCenter = () => {
    const {auditID} = useParams();
    const [audit, setAudit] = useState();

    useEffect(() => {
        if (auditID) {
            makeGetRequest(`${API_AUDITS.audits}${auditID}/`).then(
                (res) => {
                    setAudit(res);
                },
            );
        }
    }, [auditID]);

    return <div>
        {audit && <>
            Audit {auditID};

            Items:
            {audit.audit_issues.map((issue, index) => (
                <>
                    ID {index + 1}
                    <MediaViewer
                        key={index}
                        isViewOnly={true}
                        item={issue}
                        annotationType={AnnotationType.ISSUE}
                    />
                </>
            ))}

            Lines:
            {audit.audit_lines.map((line, index) => (
                <>
                    ID {index + 1}
                    <MediaViewer
                        key={index}
                        isViewOnly={true}
                        item={line}
                        annotationType={AnnotationType.LINE}
                    />
                </>
            ))}

            Areas:
            {audit.audit_areas.map((area, index) => (
                <>
                    ID {index + 1}
                    <MediaViewer
                        key={index}
                        isViewOnly={true}
                        item={area}
                        annotationType={AnnotationType.AREA}
                    />
                </>
            ))}
        </>}
    </div>;
};
