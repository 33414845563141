import React, {useState} from "react";
import {useTheme} from "styled-components";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";
import {useFetchLocationsQuery} from "api/LocationTableAPI";
import {AddLocation} from "./AddLocation";
import {Location} from "./Location";
import {UserPermissions} from "Models/User/UserUtils";
import {useCurrentUser} from "Models/User/useCurrentUser";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";
import {NavLink} from "react-router-dom";
import {Button} from "@mui/material";

export const Locations = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [locationID, setLocationID] = useState(null);
    const currentUser = useCurrentUser();
    const isAdmin = UserPermissions.useIsAdmin();
    const theme = useTheme();

    let locationsColumns = {
        "ID": "id",
        "Address": "full_address",
        "Account": "account_name",
        "Property Type": "status_type.name",
        "Account Managers": "account_manager",
        "Status": "is_active",
    };

    if (isAdmin) {
        locationsColumns = {
            ...locationsColumns, "Actions": "btn",
        };
    }
    const dataParser = {
        id: (location) => (
            <NavLink
                onClick={() => {
                    setLocationID(location.id);
                    setShowEditModal(true);
                }}
            >
                {location.row.id}
            </NavLink>
        ),
        btn: (location) => <Button
            variant={"outlined"}
            onClick={
                () => window.open(`/audits/creator?locationId=${location.id}`,
                    "_blank", "noopener,noreferrer")
            }
            disabled={!location.row.is_active}
            sx={{
                "color": theme.color.textOnBg,
                "borderColor": theme.color.textOnBg,
                "textTransform": "capitalize",
                "&:hover": {
                    borderColor: theme.color.info,
                },
            }}>
            Audit
        </Button>,
    };

    return (
        <div className="locations">
            <GSPSBreadCrumbs />
            <div className="container">
                {
                    currentUser && currentUser.subscriber &&
                    <GSPSDataDrivenTable
                        columns={locationsColumns}
                        dataParser={dataParser}
                        title="Locations"
                        tableAdminBtn={
                            isAdmin && <GSPSSubmitButtonStyle
                                onClick={() => setShowAddModal(true)}
                                className="cta-btn"
                            >
                                Add new location
                            </GSPSSubmitButtonStyle>
                        }
                        useQuery={useFetchLocationsQuery}
                    />
                }
            </div>
            {showAddModal && (
                <AddLocation
                    show={showAddModal}
                    setShow={setShowAddModal}
                />
            )}
            {showEditModal && locationID && (
                <Location
                    show={showEditModal}
                    setShow={setShowEditModal}
                    locationID={locationID}
                />
            )}
        </div>
    );
};
