import React from "react";
import PropTypes from "prop-types";
import {AuditTable} from "./AuditTable";

export const AuditMapSelectedIssues = ({
    audit,
    tidySelectedIssues,
    deleteIssueCall,
    editIssueCall,
    updateElementImages,
    updateElementVideos,
    isViewOnly = true,
    isPublicAudit = false,
}) => {
    return (
        <AuditTable
            audit={audit}
            headerName={"Items"}
            isViewOnly={isViewOnly}
            items={tidySelectedIssues}
            elementType={"issue"}
            updateElementImages={updateElementImages}
            updateElementVideos={updateElementVideos}
            onDelete={deleteIssueCall}
            onEdit={editIssueCall}
            dimensionHeader="Dimension"
            isPublicAudit={isPublicAudit}
        />
    );
};

AuditMapSelectedIssues.propTypes = {
    audit: PropTypes.object,
    tidySelectedIssues: PropTypes.array,
    deleteIssueCall: PropTypes.func,
    editIssueCall: PropTypes.func,
    selectedIssues: PropTypes.array,
    updateElementImages: PropTypes.func,
    updateElementVideos: PropTypes.func,
    isViewOnly: PropTypes.bool,
    isPublicAudit: PropTypes.bool,
};
