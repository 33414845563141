import {getCorePrefix} from "Services/Endpoints";

const prefix = getCorePrefix();

export const API_AUDITS = {
    audits: `${prefix}audits/`,
    auditsTypes: `${prefix}audits/types/`,
    auditsTypesItems: `${prefix}audits/items`,
    questions: `${prefix}questions`,
    issuesSeverities: `${prefix}audits/issues/severities/`,
    auditStatuses: `${prefix}audits/statuses/`,
    stats: `${prefix}audits/stats/`,
    auditElementImage: (element, elementId) => `${prefix}audits/${element}/${elementId}/image/`,
    auditElementVideo: (element, elementId) => `${prefix}audits/${element}/${elementId}/video/`,
    auditIssue: `${prefix}audits/issues/`,
    auditLine: `${prefix}audits/lines/`,
    auditArea: `${prefix}audits/areas/`,
    publicAudits: `${prefix}public-audits/`,
    bulkDeleteIssues: `${prefix}audits/issues/bulk-delete/`,
    AuditEmail: `${prefix}audit-email/`,
    bulkDeleteLines: `${prefix}audits/lines/bulk-delete/`,
    bulkDeleteAreas: `${prefix}audits/areas/bulk-delete/`,
};
