import {Col} from "react-bootstrap";
import {AuditImages, AuditVideos} from "Pages/Audits/AuditMedia";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const MediaViewer = ({
    isViewOnly,
    handleDelete,
    item,
    annotationType,
    updateElementImages,
    loadingProgress,
    isVideoCompressed,
    updateElementVideos,
    loadingVideo,
    videoCompressionFeature,
}) => {
    return <Col xs={10} md={6}>
        <>
            <span className="fw-bold pb-0">
                {!isViewOnly ? "Add" : ""} Image
            </span>
            <AuditImages
                rowId={item?.displayNumber}
                rowImages={[...item[`${annotationType}_images`]]}
                updateElementImages={updateElementImages}
                elementType={annotationType}
                isViewOnly={isViewOnly}
                limit={3}
                deleteMedia={handleDelete}
            />
            {loadingVideo && videoCompressionFeature ?
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                    }}
                ><CircularProgress
                        variant="determinate"
                        value={loadingProgress}
                        sx={{color: "#8CFAD0"}}
                    />
                    <p style={{margin: 0}}>
                        {isVideoCompressed ? "Saving" : "We are compressing your video"}....
                    </p>
                </Box> :
                <>
                    <span className="fw-bold pb-0">
                        {!isViewOnly ? "Add" : ""} Video
                    </span>
                    <AuditVideos
                        rowId={item.displayNumber}
                        rowVideos={item[`${annotationType}_videos`]}
                        updateElementVideos={updateElementVideos}
                        elementType={annotationType}
                        isViewOnly={isViewOnly}
                        limit={2}
                        deleteMedia={handleDelete}
                    />
                    {!isViewOnly &&
                        <small style={{color: "gray"}}>Maximum video duration: 30 seconds</small>
                    }
                </>
            }
        </>
    </Col>;
};

MediaViewer.propTypes = {
    isViewOnly: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        displayNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        image_annotation_images: PropTypes.arrayOf(PropTypes.string),
        video_annotation_videos: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    annotationType: PropTypes.string.isRequired,
    handleDelete: PropTypes.func,
    updateElementImages: PropTypes.func,
    loadingProgress: PropTypes.number,
    isVideoCompressed: PropTypes.bool,
    updateElementVideos: PropTypes.func,
    loadingVideo: PropTypes.bool,
    videoCompressionFeature: PropTypes.bool,
};
