import React, {useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {makeGetRequest, makePatchRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSTextArea} from "Components/Common/GSPSTextAreaInput";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import {InfoForm} from "./InfoForm.styles";
import {useUpdateAuditMutation} from "api/AuditTableAPI";
import {SVPToolTipIcon} from "Components/ToolTip/SVPToolTipIcon";

export const AuditorInformation = ({
    audit,
    setAudit,
    user,
    isReadOnly,
    isPublicAudit = false,
}) => {
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();
    const [selectedAuditor, setSelectedAuditor] = useState();
    const [auditStatuses, setAuditStatuses] = useState([]);
    const [auditStatus, setAuditStatus] = useState();

    const navigate = useNavigate();
    const autosaveNotesTimeout = useRef(null);
    const [notes, setNotes] = useState(audit?.auditor_additional_notes ?? "");
    const [updateAudit] = useUpdateAuditMutation();

    useEffect(() => {
        if (!isReadOnly) {
            makeGetRequest(`${API_AUDITS.auditStatuses}`).then(
                (res) => {
                    setAuditStatuses(res.results);
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNotes(audit?.auditor_additional_notes ?? "");
        setSelectedAuditor(audit?.auditor ?? user);
        setAuditStatus(auditStatuses?.find((status) => status.id === audit?.audit_status?.id)?.name
            ?.trim().toLowerCase());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audit]);

    const onSubmit = async () => {
        try {
            const updatedAudit = {
                ...audit,
                auditor_id: selectedAuditor?.id,
                auditor_additional_notes: notes,
                audit_status_id: auditStatuses?.find((status) => status.name === "In Review")?.id,
                audit_type_id: audit.audit_type.id,
                location: {
                    ...audit.location,
                    account_id: audit.location.account.id,
                },
                lines: audit.audit_lines,
                areas: audit.audit_areas,
            };
            const jsonRes = await updateAudit({id: audit.id, updatedItem: updatedAudit}).unwrap();
            insertNotification("Success", `Audit ${jsonRes.id} has been updated.`, "success");
            setAudit(jsonRes);
        } catch (e) {
            insertNotification("Error", `Can't update audit: ${e.message || e}`, "error");
        }
    };

    const updateNotes = async (notes) => {
        clearTimeout(autosaveNotesTimeout.current);
        autosaveNotesTimeout.current = setTimeout(async () => {
            if (audit && notes !== audit?.auditor_additional_notes && !isReadOnly) {
                const updatedAudit = {
                    auditor_additional_notes: notes,
                };
                makePatchRequest(`${API_AUDITS.audits}${audit.id}/`, updatedAudit);
            }
        }, 1000);
    };

    const handleBackToDashboard = () => {
        navigate("/dashboard");
    };

    return (
        <InfoForm>
            <div className="container" id="audit-info">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <h1 className="subtitle-md">
                        Auditor Information
                    </h1>
                    <div className="row">
                        <div className="col-md-8 col-12 ps-4 d-flex flex-column justify-content-between">
                            <div className="row">
                                <h2 className="col-md-5 col-12 body-md d-flex align-items-center">
                                    Reviewer Name
                                </h2>
                                <div className="col">
                                    <GSPSLabelledInput
                                        controlType={"input"}
                                        id={"auditor"}
                                        labelName={"Auditor"}
                                        errors={errors}
                                        register={register}
                                        isDisabled={true}
                                        value={selectedAuditor?.full_name ?? audit?.auditor?.full_name}
                                        hiddenLabel={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <h2 className="col-md-5 col-12 body-md d-flex align-items-center">
                                    Phone number
                                </h2>
                                <div className="col">
                                    <GSPSLabelledInput
                                        controlType={"input"}
                                        id={"auditorPhone"}
                                        labelName={"Phone"}
                                        errors={errors}
                                        register={register}
                                        isDisabled={true}
                                        value={selectedAuditor?.mobile_phone}
                                        hiddenLabel={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <h2 className="col-md-5 col-12 body-md d-flex align-items-center">
                                    Email
                                </h2>
                                <div className="col">
                                    <GSPSLabelledInput
                                        controlType={"input"}
                                        id={"auditorEmail"}
                                        labelName={"Email"}
                                        errors={errors}
                                        isDisabled={true}
                                        register={register}
                                        value={selectedAuditor?.email}
                                        hiddenLabel={true}
                                    />
                                </div>
                            </div>
                        </div>
                        {!isPublicAudit &&
                            <div className="col-md-4 col-12">
                                <h2 className="body-md mb-2">
                                    Additional Notes
                                    <SVPToolTipIcon
                                        fontSize="20px"
                                        placement="top"
                                        message="Internal"
                                        rightMargin = "5px"
                                    />
                                </h2>
                                <GSPSTextArea
                                    value={audit?.auditor_additional_notes}
                                    labelName={"Additional Notes"}
                                    id={"additionalNotes"}
                                    controlType={"textarea"}
                                    isRequired={false}
                                    register={register}
                                    isDisabled={isReadOnly || auditStatus === "in review"}
                                    rows={7}
                                    errors={errors}
                                    setValue={setValue}
                                    charLimit={700}
                                    setDescription={setNotes}
                                    autoSave={true}
                                    updateOnChange={updateNotes}
                                    hiddenCharLimit={true}
                                    hiddenLabel={true}
                                />
                            </div>
                        }
                    </div>
                    {!isReadOnly && (
                        <>
                            <div className="d-flex justify-content-end gap-2">
                                <Button
                                    onClick={handleBackToDashboard}
                                    variant={"contained"}
                                    className="float-end me-3 mt-3 cta-btn-secondary"
                                >
                                    Navigate To Dashboard
                                </Button>
                                <Button
                                    type="submit"
                                    variant={"contained"}
                                    className="mt-3 cta-btn"
                                    disabled={auditStatus === "in review"}
                                >
                                    Submit For Review
                                </Button>
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </InfoForm>
    );
};

AuditorInformation.propTypes = {
    audit: PropTypes.object,
    setAudit: PropTypes.func,
    user: PropTypes.object,
    isReadOnly: PropTypes.bool,
    isPublicAudit: PropTypes.bool,
};
